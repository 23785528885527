<template>
  <div class="main2">
    <div class="title">الاخبار</div>
    <!-- <div class="page1">
      <div>
        <img
          src="../assets/pic/alexport.jpeg"
          class="imgone"
          alt="مصر للصرافة"
        />
        <img
          src="../assets/pic/sidibishr.jpeg"
          class="imgone"
          alt="مصر للصرافة"
        />
        <img src="../assets/pic/semoha.jpeg" class="imgone" alt="مصر للصرافة" />
        <div class="texttwo">
          زيارة الادارة العليا للشركة لفروع الاسكندرية (الميناء - سموحة - سيدى
          بشر - سيدى جابر ) وقريبا افتتاح فروع الشركة الاتية (فرع احمد عرابى
          بالجيزة - حدائق القبة بالقاهرة - المنتزة بالاسكندرية - خليج نعمة و
          السوق القديم بجنوب سيناء - الاسماعيلية - البلينا بسوهاج - السنبلاوين
          بالدقهلية)
        </div>
      </div>
    </div> -->
    <!-- <div class="page1">
      <div>
        <img src="../assets/1.jpeg" class="imgone" alt="مصر للصرافة" />
        <div class="textone">
          تعلن شركة مصر للصرافة عن افتتاح فروع جديدة بالقاهرة ( عين شمس - مصر
          الجديدة - مكرم عبيد) ليصل عدد فروع الشركة 57 فرع فى محافطات مصر وقريبا
          سيتم افتتاح فروع جديدة بمحافظات اخرى (شبين القناطر - سيدي جابر -
          المنتزه - شرم الشيخ السوق القديم 2 - شرم الشيخ خليج نعمه)
        </div>
      </div>
    </div> -->
    <!-- <div class="page1">
      <div>
        <img src="../assets/alaqsor..jpeg" class="imgone" />
        <div class="textone">
          تم نقل اعمال فرع الاقصر فى العنوان : شارع معبد الكرنك امام معبد الاقصر
          البوابة الخلفية – محافظة الاقصر نتشرف باستقبال العملاء فى المواعيد
          الخاصة بالفرع من السبت الى الخميس من ( 8:30 ص ) حتي ( 8:30 م ) والجمعة
          من ( 1:00 م ) حتي ( 8:00 م )
        </div>
      </div>
    </div> -->
    <div class="page1">
      <div>
        <img src="../assets/logo.png" class="imgone" />
        <div class="textone">
          <div>الان فى الاوبرا وسط البلد</div>
          <div>
            تم افتتاح فرع الاوبرا بمنطقة وسط البلد ليصل عدد فروع الشركة الى 70
            فرع و تعمل الشركة على توفير عدد اكبر من الفروع فى جميع المحافظات
            لمعرفة جميع فروع الشركة و الوصول الينا
            <a href="#" @click="branch">اضغط هنا</a>
          </div>
        </div>
      </div>
    </div>
    <div class="page1">
      <div>
        <img src="../assets/logo.png" class="imgone" />
        <div class="textone">
          <div>قريبا فى الزمالك القاهرة</div>
          <div>سيتم افتتاح فرع الزمالك ليصل عدد فروع الشركة الى 71 فرع</div>
        </div>
      </div>
    </div>
  </div>
  <div class="end"></div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "CompNews",
  methods: {
    branch() {
      this.redirectTo({ val: "AddressV" });
    },
    ...mapActions(["redirectTo"]),
  },
};
</script>
<style scoped>
.main2 {
  direction: rtl;
  width: 90%;
  margin-left: 5%;
  clear: both;
}
.title {
  width: 25%;
  background-color: #871e35;
  color: white;
  font-size: xx-large;
  padding: 2%;
  margin-top: 5%;
}
.page1 {
  width: 85%;
  background-color: white;
  margin-right: 15%;
  margin-top: -2%;
  text-align: right;
  padding: 1%;
  clear: both;
}
.imgone {
  width: 300px;
  height: 200px;
  float: right;
}
.textone {
  width: 65%;
  height: 100%;
  float: right;
  margin-right: 2%;
  margin-top: 3%;
  font-size: xx-large;
}
.texttwo {
  width: 90%;
  height: 100%;
  float: right;
  margin-right: 2%;
  margin-top: 3%;
  font-size: xx-large;
}
.end {
  clear: both;
}
@media screen and (max-width: 650px) {
  .main2 {
    direction: rtl;
    width: 90%;
    margin-left: 5%;
    clear: both;
  }
  .title {
    width: 50%;
    background-color: #871e35;
    color: white;
    font-size: xx-large;
    padding: 2%;
  }
  .page1 {
    width: 85%;
    background-color: white;
    margin-right: 10%;
    margin-top: -2%;
    text-align: right;
    padding: 1%;
    clear: both;
  }
  .imgone {
    width: 100%;
    height: 100%;
    float: right;
  }
  .textone {
    width: 100%;
    height: 100%;
    float: right;
    margin-right: 2%;
    margin-bottom: 5%;
    font-size: large;
    margin-top: 5%;
  }
  .texttwo {
    width: 100%;
    height: 100%;
    float: right;
    margin-right: 2%;
    margin-bottom: 5%;
    font-size: large;
    margin-top: 5%;
  }
}
</style>
