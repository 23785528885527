import { createRouter, createWebHistory } from 'vue-router'

import HomePage from '../views/Home.vue'
import AboutUs from '../views/AboutUs.vue'
import PriseV from '../views/PriseV.vue'
import AddressV from '../views/AddressV.vue'

import HomePageEN from '../views/HomeEn.vue'
import AboutUsEN from '../views/AboutUsEN.vue'
import PriseVEN from '../views/PriseVEN.vue'
import AddressVEN from '../views/AddressVEN.vue'

import CareerV from '../views/CareerV.vue'
import CareerVEN from '../views/CareerVEN.vue'

import ContactUsV from '../views/ContactUsV.vue'
import ContactUsVEN from '../views/ContactUsVEN.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/page',
    name: 'HomePageEN',
    component: HomePageEN
  },
  {
    path: '/aboutus',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/aboutus/EN',
    name: 'AboutUsEN',
    component: AboutUsEN
  },
  {
    path: '/Prise',
    name: 'PriseV',
    component: PriseV
  },
  {
    path: '/Prise/EN',
    name: 'PriseVEN',
    component: PriseVEN
  },
  {
    path: '/AddressV',
    name: 'AddressV',
    component: AddressV
  },
  {
    path: '/AddressV/EN',
    name: 'AddressVEN',
    component: AddressVEN
  },

  {
    path: '/CareerV',
    name: 'CareerV',
    component: CareerV
  },
  {
    path: '/CareerV/EN',
    name: 'CareerVEN',
    component: CareerVEN
  },
  {
    path: '/ContactUsV',
    name: 'ContactUsV',
    component: ContactUsV
  },
  {
    path: '/ContactUsV/EN',
    name: 'ContactUsVEN',
    component: ContactUsVEN
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
