<template>
<NavBarVue />
    <div class="page">
        <h1>تاريخ الشركة</h1>
        <div class="one">
            <img class="one" src="../assets/exchange.jpg" />
        </div>
        <div class="hist">
            <p>
                تأسست شركة مصر للصرافة (شركة مساهمه مصرية) بتاريخ 4/5/1991 طبقاً لأحكام القوانين المعمول بها في جمهوريه
                مصر العربية ووفقاً لأحكام قانون شركات المساهمة بالقانون رقم 159 لسنة 1981 ولائحته التنفيذية وتعديلاته و
                أحكام قانون النقد الأجنبي رقم 97 لسنة 1976 و لائحته التنفيذية ووفقا لأحكام القانون رقم 38 سنة 2003 في
                شأن البنك المركزي المصري و الجهاز المصرفي و النقد و تعديلاته و لائحته التنفيذية
                <br />صدر للشركة سجل تجارى برقم 16681 بتاريخ 4/5/1991
                تم الترخيص للشركة بالتعامل في شراء و بيع العملات الأجنبية بموجب القرار الوزاري رقم 381 لسنة 1991 بتاريخ
                8/8/1991 و المنشور بالجريدة الرسمية بالعدد 183 بتاريخ 17/8/1991 طبقا لأحكام المادة 3 من الائحة التنفيذية
                <br />
                يخضع نشاط الشركة طبقا للقانون رقم 88 لسنة 2003 وتعديلاته ولائحته التنفيذية <br />

            </p>
        </div>
        <hr />
        <h1>مدة الشركة</h1>
        <div class="hist">
            <p>
                بتاريخ 26/4/2016 اجتمعت الجمعية العامة غير عادية و قررت تعديل المادة (5) التى تنص إن المدة المحددة
                للشركة 25 سنة تبدأ من تاريخ قيد الشركة بالسجل التجارى لتصبح المدة المحددة للشركة 25 سنة تبدأ من 3/5/2014
                حتى 4/5/2039 .

            </p>
        </div>
        <hr />
        <h1>نشاط الشركة</h1>
        <div class="hist">
            <p>
                شراء و بيع العملات الأجنبية للعملاء من خلال مركزها الرئيسي بالإضافة إلي عدد 54
                فرع منتشرة بمحافظات جمهورية مصر العربية .

            </p>
        </div>
        <hr />
        <h1>ميثاق عمل الشركة</h1>
        <div class="hist">
            <h3>رؤيتنا</h3>
            <ul>
            <p>
                أن نكون الشركة الرائدة فى سوق الصرافة .
            </p></ul>
            <h3>رسالتنا</h3>
            <ul>
            <p>
                النزاهه في المعاملة – كسب رضا العميل – التميز والجوده .
            </p></ul>
            <h3>هدفنا</h3>
            <p>
            <ul>
                <!-- <p>الوصول بعدد فروع الشركة الى 70 فرع بنهاية عام 2022 .</p> -->
                <p>تعظيم الربحية وحقوق الملكية للشركة .</p>
                <p> الحفاظ على العملاء الحاليين واستهداف عملاء جدد وتكوين قاعدة بيانات لعملاء الشركة .
                </p>
                <p>التدريب الدائم للعاملين لاكسابهم المهارات اللازمة وتأدية مهامهم باكبر قدر من المهاره و الكفاءة لخدمة العملاء .</p>
            </ul>
            </p>
        </div>
        <!-- <div class="ceo">
            <hr/>
            <h1>أعضاء مجلس الإدارة</h1>
            <br/>
            <div class="row">
                <div class="column">
                    <div class="card">
                        
                        <img src="../assets/mradel.png" style="height:300px;" />
                        <div class="container">
                            <br/>
                            <h2>أ/عادل فوزى</h2>
                            <p>رئيس مجلس الادارة والعضو المنتدب</p>
                        </div>
                    </div>
                </div>

                <div class="column">
                    <div class="card">
                        <img src="../assets/mrtarek.png" style="height:300px;" />
                        <div class="container">
                            <h2>أ/طارق لطفى </h2>
                            <p>مــدير عـــام الشركــــة </p>
                        </div>
                    </div>
                </div>

            </div>
        </div> -->
    </div>
    
</template>
<script>
import NavBarVue from '../components/NavBar.vue'
export default {
    name: "AboutUs",

    components: {
        NavBarVue
    }
}
</script>
<style scoped>
.page {
    direction: rtl;
    font-family: "Ishraq";
}

.one {
    width: 80%;
    height: 300px;
        margin-right: 5%;
}


.hist {
    margin-top: 2%;
    text-align: right;
    width: 80%;
    margin-right: 10%;
    font-size: xx-large;
}

h1 {
    color: #880404;
}

h3 {
    color: #880404;
}
hr{
    width: 70%;
    margin-right: 15%;
}

.ceo{
    width: 80%;
    margin-right: 10%;
}
/* Three columns side by side */
.column {
    width: 33.3%;
    padding: 2%
}
.column:hover{
        box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px 5px;
}
/* Display the columns below each other instead of side by side on small screens */
@media screen and (max-width: 650px) {
    .column {
        width: 100%;
        display: block;
    }
    p{
    font-family:sans-serif;
    font-size: small;
}
}

/* Add some shadows to create a card effect */

/* Some left and right padding inside the container */
.container {
    padding: 0 16px;
}

/* Clear floats */
.container::after,
.row::after {
    content: "";
    clear: both;
    display: table;
}

.title {
    color: grey;
}

</style>