<template>
  <NavBarVue />
  <div class="page">
    <div class="page1">
      <h1>الوظائف</h1>
      <p>
        نحن نمتلك فريقاً ذا احترافية عالية ونعمل على التوسع باستمرار ودائمًا ما
        نبحث عن أشخاص محترفين للانضمام إلينا.
      </p>
    </div>
    <div class="page2">
      <h3>الوظائف و المتطلبات للوظائف</h3>
        <p>
          <ul>
            <h4>الفروع</h4>
            <li>
              مدير فرع : للتقديم على وظائف مدير فرع بالشركة يجب ان يتوفر
              <ul>
                  <li>خبرة فى مجال البنوك مع توافر شهادة خبرة</li>
                  <li>عدم وجود سابقة عمل باحد شركات الصرافة الاخرى</li>
              </ul>
            </li>
            <li>
              صراف : الشروط المطلوبة
              <ul>
                <li>الخرجين فقط من احد الجامعات العليا</li>
                <li>ان لا يزيد العمر عن 27 سنة</li>
              </ul>
            </li>
            <h4>الفرع الرئيسي</h4>
            تتوافر مجموعة من الوظائف الداعمة والمساندة مثل قطاع الموارد البشرية، والشؤون القانونية، والمالية، واتصالات المؤسسة، والاستراتيجية.
          </ul>
        </p>
        <p>يمكنك التقديم على الوظائف من خلال ارسال السيرة الذاتية على البريد الالكتروني </p>
        <p>hr@misrexchange.com</p>
        <hr />
        <!-- <h2>الوظائف المتاحة</h2>
        <ul>
          <li>مديرين فروع بفروع 
            <ul>
              <li>رشيد</li>
              <li>شبين القناطر</li>
              <li>عين شمس</li>
              <li>مصر الجديدة</li>
              <li>حدائق القبة</li>
              <li>مكرم عبيد مدينة نصر</li>
              <li>المنتزة</li>
              <li>شرم الشيخ</li>
            </ul>
          </li>
          <li>تلر
            <ul>
              <li>قنا</li>
            </ul>
          </li>
        </ul> -->
    </div>
    <div><img src="../assets/9.jpg" /></div>
  </div>
</template>
<script>
import NavBarVue from "../components/NavBar.vue";
export default {
  name: "CareerV",
  components: {
    NavBarVue,
  },
};
</script>
<style scoped>
.page {
  direction: rtl;
}
h1 {
  margin-top: 2%;
  font-family: "Ishraq";
  font-size: 500%;
}
p {
  font-family: "Ishraq";
}
.page1 {
  color: white;
  width: 100%;
  height: 500px;
  padding: 2%;
  text-align: right;
  font-family: "Ishraq";
  background-image: url("../assets/back1.png");
  font-size: x-large;
}
.page1 h1 {
  margin-right: 10%;
}
.page2 {
  width: 90%;
  margin: 5%;
  text-align: right;
  font-family: "Ishraq";
}
@media screen and (max-width: 650px) {
  .page {
    direction: rtl;
  }
  h1 {
    margin-top: 2%;
    font-family: "Ishraq";
    font-size: 500%;
  }
  p {
    font-family: "Ishraq";
  }
  .page1 {
    color: white;
    width: 100%;
    height: 300px;
    padding: 2%;
    text-align: right;
    font-family: "Ishraq";
    background-image: url("../assets/back1.png");
    background-size: 100% 100%;
    font-size: large;
  }
  .page1 h1 {
    margin-right: 10%;
  }
}
</style>
