<template>
  <div class="main">
    <div class="one">
      <div class="card">
        <div class="content">
          <div class="front">
            <h2 class="title">استبدال العملة للافراد</h2>
            <p class="subtitle">يمكن استبدال العملة الاجنبية للسفر</p>
            <p class="subtitle">لمزيد من المعلومات</p>
          </div>
          <div class="back">
            <p class="description">
              عملائنا الكرام يمكنك استبدال العملة الاجنبية للسفر فى الخارج من
              خلال اقرب فرع للشركة لمعرفة فروع الشركة
              <a href="#" @click="branches">اضغط هنا</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="one">
      <div class="card">
        <div class="content2">
          <div class="front">
            <h2 class="title">لتعاملات الشركات</h2>
            <p class="subtitle">لمزيد من التعليمات</p>
          </div>
          <div class="back2">
            <p class="description">
              لاستبدال العملة للشركات يلزم توافر الاوراق الخاصة بالشركة للتواصل
              معنا
              <a href="#" @click="branches">اضغط هنا</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    
  </div>
  <div class="end"></div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "NewsV",
  methods: {
    branches() {
      this.redirectTo({ val: "AddressV" });
    },
    ...mapActions(["redirectTo"]),
  },
};
</script>
<style scoped>
.main {
  width: 90%;
  margin: 5%;
  height: 400px;
  clear: both;
  margin-top:5%
}
.one {
  width: 23.5%;
  float: right;
  margin: 0.5%;
  height: 100%;
  color: white;
  font-size: x-large;
  margin-right: 16%;
}
.end {
  clear: both;
}
a {
  color: white;
}

/* From uiverse.io by @JoseIsra */
.card {
  cursor: pointer;

  height: 100%;
}

.content {
  text-align: center;
  position: relative;
  transition: all 2.25s;
  background-image: url("../assets/5.jpg");
  background-size: 100% 150%;
  height: 100%;
  background-repeat: no-repeat;
  padding: 5em;
  transform-style: preserve-3d;
}
.content2 {
  text-align: center;
  position: relative;
  transition: all 2.25s;
  background-image: url("../assets/7.jpg");
  background-size: 100% 50%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-color: black;
  height: 100%;

  padding: 5em;
  transform-style: preserve-3d;
}

.card:hover .content {
  transform: rotateY(0.5turn);
}

.card:hover .content2 {
  transform: rotateY(0.5turn);
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 2em;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  height: 100%;
}
.front,
.back2 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 2em;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  height: 100%;
}

.title {
  transform: translateZ(5rem);
  font-size: 2rem;
}

.subtitle {
  transform: translateZ(2rem);
}

.back {
  transform: rotateY(0.5turn);
  background-image: url("../assets/5.jpg");
  background-size: 100% 150%;
}
.back2 {
  transform: rotateY(0.5turn);
  background-image: url("../assets/7.jpg");
  background-size: 100% 50%;
  background-position: bottom;
  background-repeat: no-repeat;
}

.description {
  transform: translateZ(3rem);
}

@media screen and (max-width: 650px) {
.main {
  width: 95%;
  margin: 5%;
  height: 400px;
  clear: both;
}
.one {
  width: 75%;
  float: right;
  margin: 0.5%;
  height: 100%;
  color: white;
  font-size: large;
  margin-right: 16%;
}
.end {
  clear: both;
}
a {
  color: white;
}

/* From uiverse.io by @JoseIsra */
.card {
  cursor: pointer;

  height: 100%;
}

.content {
  text-align: center;
  position: relative;
  transition: all 2.25s;
  background-image: url("../assets/5.jpg");
  background-size: 100% 150%;
  height: 100%;
  background-repeat: no-repeat;
  padding: 5em;
  transform-style: preserve-3d;
}
.content2 {
  text-align: center;
  position: relative;
  transition: all 2.25s;
  background-image: url("../assets/7.jpg");
  background-size: 100% 50%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-color: black;
  height: 100%;

  padding: 5em;
  transform-style: preserve-3d;
}

.card:hover .content {
  transform: rotateY(0.5turn);
}

.card:hover .content2 {
  transform: rotateY(0.5turn);
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 2em;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  height: 100%;
}
.front,
.back2 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 2em;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  height: 100%;
}

.title {
  transform: translateZ(5rem);
  font-size: 2rem;
}

.subtitle {
  transform: translateZ(2rem);
}

.back {
  transform: rotateY(0.5turn);
  background-image: url("../assets/5.jpg");
  background-size: 100% 150%;
}
.back2 {
  transform: rotateY(0.5turn);
  background-image: url("../assets/7.jpg");
  background-size: 100% 50%;
  background-position: bottom;
  background-repeat: no-repeat;
}

.description {
  transform: translateZ(3rem);
}
}
</style>
