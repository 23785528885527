<template>
  <div class="main">
    <div class="one">
      <h1>Currency exchange calculator</h1>
    </div>
    <div class="two">
      <div class="four">
        <h3>Type Of Transaction</h3>
        <select id="cars" name="cars" v-model="transtype">
          <option value="sell">Selling currency to the company</option>
          <option value="buy">Buying currency from the company</option>
        </select>
      </div>
      <div class="four" v-if="transtype === 'buy'">
        <label>Choose Currancy : &nbsp;</label>
        <select v-model="currancybuy" v-for="value in currancy" :key="value">
          <option :value="value.usdsell">{{ value.enusd }}</option>
          <option :value="value.eursell">{{ value.eneur }}</option>
          <option :value="value.gbpsell">{{ value.engbp }}</option>
          <option :value="value.gbpsell">{{ value.ensar }}</option>
          <option :value="value.aedsell">{{ value.enaed }}</option>
          <option :value="value.kwdsell">{{ value.enkwd }}</option>
          <option :value="value.audsell">{{ value.enaud }}</option>
          <option :value="value.jpysell">{{ value.enjpy }}</option>
          <option :value="value.seksell">{{ value.ensek }}</option>
          <option :value="value.jodsell">{{ value.enjod }}</option>
          <option :value="value.chfsell">{{ value.enchf }}</option>
          <option :value="value.bhdsell">{{ value.enbhd }}</option>
          <option :value="value.qarsell">{{ value.enqar }}</option>
          <option :value="value.omrsell">{{ value.enomr }}</option>
          <option :value="value.cadsell">{{ value.encad }}</option>
        </select>
        <input type="number" v-model="equalcurrancybuy" disabled />
      </div>
      <div class="four" v-if="transtype === 'buy'">
        <input type="text" value="EGP" disabled />
        <input type="text" v-model="egpbuy" />
      </div>
      <div class="four" v-if="transtype === 'sell'">
        <label>Choose Currancy : &nbsp;</label>
        <select v-model="currancysell" v-for="value in currancy" :key="value">
          <option :value="value.usdbuy">{{ value.enusd }}</option>
          <option :value="value.eurbuy">{{ value.eneur }}</option>
          <option :value="value.gbpbuy">{{ value.engbp }}</option>
          <option :value="value.sarbuy">{{ value.ensar }}</option>
          <option :value="value.aedbuy">{{ value.enaed }}</option>
          <option :value="value.kwdbuy">{{ value.enkwd }}</option>
          <option :value="value.audbuy">{{ value.enaud }}</option>
          <option :value="value.jpybuy">{{ value.enjpy }}</option>
          <option :value="value.sekbuy">{{ value.ensek }}</option>
          <option :value="value.jodbuy">{{ value.enjod }}</option>
          <option :value="value.chfbuy">{{ value.enchf }}</option>
          <option :value="value.bhdbuy">{{ value.enbhd }}</option>
          <option :value="value.qarbuy">{{ value.enqar }}</option>
          <option :value="value.omrbuy">{{ value.enomr }}</option>
          <option :value="value.cadbuy">{{ value.encad }}</option>
        </select>
        <input type="number" v-model="equalcurrancysell" />
      </div>
      <div class="four" v-if="transtype === 'sell'">
        <input type="text" value="EGP" disabled />
        <input type="text" v-model="egpsell" disabled />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CurranciesCalculator",
  computed: {
    currancy() {
      return this.$store.state.currancy;
    },
  },
  data() {
    return {
      transtype: "sell",
      egpsell: "",
      equalcurrancysell: "",
      currancysell: "",
      egpbuy: "",
      equalcurrancybuy: "",
      currancybuy: "",
    };
  },
  watch: {
    equalcurrancysell() {
      this.egpsell = (Number(this.equalcurrancysell) * Number(this.currancysell)).toFixed(2);
    },
    egpbuy() {
      this.equalcurrancybuy = (Number(this.egpbuy) / Number(this.currancybuy)).toFixed(2);
    },
    currancybuy() {
      this.equalcurrancybuy = this.currancybuy;
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  direction: ltr;
  margin-top: 5%;
}
.one {
  width: 25%;
  background-color: #871e35;
  color: white;
  font-size: large;
  padding: 1%;
  margin-left: 5%;
}
.two {
  width: 65%;
  box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px 15px;
  background-color: white;
  margin-left: 20%;
  padding: 3%;
  margin-top: -1%;
}
.four {
  color: #871e35;
  font-size: x-large;
}
input {
  margin-left: 1%;
  margin-top: 0.5%;
  text-align: center;
}
select {
  background: #871e35;
  color: white;
  border-radius: 0.2em;
}
@media (max-width: 767px) {
  .main {
    width: 100%;
    direction: ltr;
  }
  .one {
    width: 50%;
    background-color: #871e35;
    color: white;
    font-size: small;
    padding: 1%;
    margin-left: 5%;
  }
  .two {
    width: 65%;
    box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px 15px;
    background-color: white;
    margin-left: 20%;
    padding: 3%;
    margin-top: -1%;
  }
  .four {
    color: #871e35;
    font-size: small;
  }
  input {
    margin-left: 1%;
    margin-top: 0.5%;
    text-align: center;
    padding: 2%;
  }
  select {
    background: #871e35;
    color: white;
    border-radius: 0.2em;
    padding: 2%;
  }
}
</style>
