<template>
  <NavBarVue />
  <div class="page">
    <div class="page1">
      <h1>رضاؤكم هدفنا.. يمكنكم المتابعة معنا من خلال الوسائل التالية</h1>
    </div>
    <div class="page2">
      <h3>تلفونيا على الارقام التالية</h3>
      <ul>
        <li>الفرع الرئيسي : 01146236280 / 01110001521</li>
        <li>
          للتواصل مع فروع الشركة <a href="#" @click="branches">اضغط هنا</a>
        </li>
      </ul>
      <h3>او عن طريق البريد الالكترونى :</h3>
      <p>M.EX@misrexchange.com</p>
      <h3>للشكاوى و المقترحات برجاء التواصل عن طريق البريد الالكترونى</h3>
      <p>complaint@misrexchange.com</p>
    </div>
  </div>
</template>
<script>
import NavBarVue from "../components/NavBar.vue";
import { mapActions } from "vuex";
export default {
  name: "ContactUsV",
  components: {
    NavBarVue,
  },
  methods: {
    branches() {
      this.redirectTo({ val: "AddressV" });
    },
    ...mapActions(["redirectTo"]),
  },
};
</script>
<style scoped>
.page {
  direction: rtl;
}
h1 {
  margin-top: 20%;
  font-family: "Ishraq";
  font-size: 200%;
}
p {
  font-family: "Ishraq";
}
.page1 {
  color: white;
  width: 100%;
  height: 500px;
  padding: 2%;
  text-align: right;
  font-family: "Ishraq";
  background-image: url("../assets/contact.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80% 100%;
  font-size: x-large;
}
.page1 h1 {
  margin-right: 10%;
}
.page2 {
  width: 90%;
  margin: 5%;
  text-align: right;
  font-family: "Ishraq";
  font-size: xx-large;
}
.page2 h3 {
  font-size: xx-large;
}
@media screen and (max-width: 650px) {
  .page {
    direction: rtl;
  }
  h1 {
    margin-top: 60%;
    font-family: "Ishraq";
    font-size: 100%;
  }
  p {
    font-family: "Ishraq";
  }
  .page1 {
    color: white;
    width: 100%;
    height: 300px;
    padding: 2%;
    text-align: right;
    font-family: "Ishraq";
    background-image: url("../assets/contact.jpg");
    background-size: 100% 100%;
    font-size: large;
  }
  .page1 h1 {
    margin-right: 10%;
  }
  .page2 {
    width: 90%;
    margin: 5%;
    text-align: right;
    font-family: "Ishraq";
    font-size: x-large;
  }
  .page2 h3 {
    font-size: x-large;
  }
}
</style>
