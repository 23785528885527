<template>
  <NavBarVue />
<div class="page">
    <div class="page1">
      <h1>Jobs</h1>
      <p>
        We have a highly professional team and are constantly expanding and always
        We are looking for professional people to join us.
      </p>
    </div>
    <div class="page2">
      <h3>Jobs and Job Requirements</h3>
        <p>
          <ul>
            <h4>Branches</h4>
            <li>
              Branch Manager: To apply for the positions of a branch manager in the company, it must be available
              <ul>
                  <li>Experience in banking, with experience certificate available</li>
                  <li>There is no previous work experience with any other exchange company</li>
              </ul>
            </li>
            <li>
              Teller: Required conditions
              <ul>
                <li>Only graduates from a top university</li>
                <li>Not more than 27 years old</li>
              </ul>
            </li>
            <h4>Master branch</h4>
            A range of supportive functions are available such as human resources, legal affairs, finance, corporate communications, and strategy.
          </ul>
        </p>
        <p>You can apply for jobs by sending your CV to the e-mail </p>
        <p>hr@misrexchange.com</p>
        <hr />
        <!-- <h2>Available Jobs</h2>
        <ul>
          <li>Branch Managers by Branches
             <ul>
               <li>Rasheed</li>
               <li>Shibin al-Qanater</li>
               <li>Ain Shams</li>
               <li>Heliopolis</li>
               <li>Kobba Gardens</li>
               <li>Makram Ebeid Nasr City</li>
               <li>The Park</li>
               <li>Sharm El-Sheikh</li>
             </ul>
           </li>
           <li>Teller
             <ul>
               <li>Qena</li>
             </ul>
           </li>
        </ul> -->
    </div>
    <div><img src="../assets/9.jpg" /></div>
  </div>
</template>
<script>
import NavBarVue from "../components/NavBarEn.vue";
export default {
  name: "CareerVEN",
  components: {
    NavBarVue,
  },
};
</script>
<style scoped>
.page {
  direction: ltr;
}
h1 {
  margin-top: 2%;
  font-family: "Ishraq";
  font-size: 500%;
}
p {
  font-family: "Ishraq";
}
.page1 {
  color: white;
  width: 100%;
  height: 500px;
  padding: 2%;
  text-align: left;
  font-family: "Ishraq";
  background-image: url("../assets/back1.png");
  font-size: x-large;
}
.page1 h1 {
  margin-left: 10%;
}
.page2 {
  width: 90%;
  margin: 5%;
  text-align: left;
  font-family: "Ishraq";
}
@media screen and (max-width: 650px) {
  .page {
    direction: ltr;
  }
  h1 {
    margin-top: 2%;
    font-family: "Ishraq";
    font-size: 500%;
  }
  p {
    font-family: "Ishraq";
  }
  .page1 {
    color: white;
    width: 100%;
    height: 300px;
    padding: 2%;
    text-align: left;
    font-family: "Ishraq";
    background-image: url("../assets/back1.png");
    background-size: 100% 100%;
    font-size: large;
  }
  .page1 h1 {
    margin-left: 10%;
  }
}
</style>
