<template>
  <div class="main">
    <div class="title">our locations</div>
    <div class="body">
      <div class="gov">
        <spam>
          Branch :
          <select v-model="cov">
            <option value="head">Head Office</option>
            <option value="kasrelnile">Kasr ElNile</option>
            <option value="nasrcity">Nasr City</option>
            <option value="makram">Makram Ebad</option>
            <option value="maadi">El Maadi</option>
            <option value="zaiton">Helmit Elziton</option>
            <option value="manial">Elmanil</option>
            <option value="rehab">Elrehab</option>
            <option value="masrelgdeda">Misr Elgdeda</option>
            <option value="shams">Ain Shams</option>
            <option value="qoba">Hadaya Elqoba</option>
            <option value="opera">Opera</option>
            <option value="haram">Elharram</option>
            <option value="faisal">Faissal</option>
            <option value="mohandsen">Elmohandsen</option>
            <option value="orabi">Ahmed Orabi</option>
            <option value="badrashin">Elbadrshen</option>
            <option value="october">October</option>
            <option value="banha">Benha</option>
            <option value="kafrshokr">Kafr Shokr</option>
            <option value="shbinq">Shbin Elqanater</option>
            <option value="smoha">Smooha</option>
            <option value="beshr">Sidi Bishr</option>
            <option value="mena">Alexandria port</option>
            <option value="montazah">Almontazah</option>
            <option value="gaber">Sidi Jaber</option>
            <option value="sharm">Sharm El Sheikh</option>
            <option value="old">Old Market Sharm El Sheikh</option>
            <option value="neama">Neama Bay Sharm El Sheikh</option>
            <option value="sheraton">Sheraton Hurghada</option>
            <option value="safaga">Safaga</option>
            <option value="kawsar">Al Kawthar Hurghada</option>
            <option value="diarbngm">Dairb Negm</option>
            <option value="zakazik">Zagazig</option>
            <option value="kafrsakr">Kafr Saqr</option>
            <option value="fakos">Faqous</option>
            <option value="domiat">Domiat</option>
            <option value="faraskur">Faraskur</option>
            <option value="mansora">Mansoura</option>
            <option value="elmanzala">Al Manzala</option>
            <option value="metghamr">Mit Ghamr</option>
            <option value="shbenelkom">Shebin El-Koum</option>
            <option value="sinbillawain">Sinbillawain</option>
            <option value="ashmoun">Ashmoun</option>
            <option value="mahmoudia">Mahmoudia</option>
            <option value="damanhor">Damanhour</option>
            <option value="baniswaf">Beni Suef</option>
            <option value="kafrelshakh">Kafr Elsheikh</option>
            <option value="desok">Desouq</option>
            <option value="tanta">Tanta</option>
            <option value="mahala">Mahalla</option>
            <option value="aswan">Aswan</option>
            <option value="komombo">Kom Ombo Branch</option>
            <option value="aqsor">Luxor</option>
            <option value="qena">Qena</option>
            <option value="qos">Qus</option>
            <option value="deshna">Deshna</option>
            <option value="gerga">Jerja</option>
            <option value="monshaa">Almonsha</option>
            <option value="tahta">Tahta</option>
            <option value="asyut">Asyut</option>
            <option value="hamadi">Nag Hammadi</option>
            <option value="sohag">sohag</option>
            <option value="balyana">baliana</option>
            <option value="qosia">Qusiyah</option>
            <option value="banimazar">Bani Mazar</option>
            <option value="mattai">Mattay</option>
            <option value="menia">Minya</option>
            <option value="rashid">rashid</option>
            <option value="ismailia">Ismailia</option>
          </select>
        </spam>
      </div>
      <br />
      <div class="cairo" v-if="cov === 'head'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.037792205810547%2C31.233171463012695&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 6 Ibrahim Nagib -
          Garden City - Cairo (BanqueMisr Garden city Branch)
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Sunday To Thursday (8:30
          AM) Till (3:00 PM)
        </p>
      </div>
      <div class="cairo" v-if="cov === 'kasrelnile'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.049509048461914%2C31.24477767944336&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 48 Kasr ElNile -
          Cairo
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 023957467</p>
      </div>
      <div class="cairo" v-if="cov === 'nasrcity'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.06378936767578%2C31.325660705566406&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 22 Eltayran St -
          Nasr City - Cairo
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 022623028</p>
      </div>
      <div class="cairo" v-if="cov === 'makram'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.052703857421875%2C31.346227645874023&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 24 Noureddine
          Bahgat From Makram Ebeid - Nasr City
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 025422649</p>
      </div>
      <div class="cairo" v-if="cov === 'maadi'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=29.95889663696289%2C31.252456665039062&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : No 2/180 St 151/155
          in front of town center maadi - Elmaadi - Cairo
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0227511536</p>
      </div>
      <div class="cairo" v-if="cov === 'zaiton'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps/search/Super%20Infinity%20Gym/@30.11461067199707,31.316980361938477,17z?hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 13 Ein Shams St -
          Helmit Elziton - Cairo
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0222415072</p>
      </div>
      <div class="cairo" v-if="cov === 'manial'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.0122819%2C31.2245559&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 9 Elmokhtar St -
          Manil Elrowda - Cairo
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0223659427</p>
      </div>
      <div class="cairo" v-if="cov === 'rehab'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.0564022064209%2C31.49114418029785&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Commercial Shop No
          (189) on the ground floor of the home services group in the Al-Rehab
          project - the commercial market - Al-Rehab
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0226078895</p>
      </div>
      <div class="cairo" v-if="cov === 'masrelgdeda'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.098590850830078%2C31.323211669921875&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 29 El Hegaz Street
          in front of Maryland Park and next to Farhat El Kababji
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (9:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 024527936</p>
      </div>
      <div class="cairo" v-if="cov === 'opera'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.051321029663086%2C31.245182037353516&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 29 Opera Square -
          Down Town - Cairo
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (9:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0223930571</p>
      </div>
      <div class="cairo" v-if="cov === 'shams'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.12693214416504%2C31.320493698120117&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Ain Shams Street
          The corner of Ibrahim Abdel Razek Street
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (9:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 024993460</p>
      </div>
      <div class="cairo" v-if="cov === 'neama'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=27.909927368164062%2C34.325618743896484&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Naama Bay - Second
          Walk Next to mcdonalds
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; All days of the week (10:00
          AM) Till (12:00 AM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0693601115</p>
      </div>
      <div class="cairo" v-if="cov === 'qoba'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.08649444580078%2C31.283159255981445&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 6 Wali Elahd st
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (9:00 AM) Till (9:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 026823606</p>
      </div>
      <div class="cairo" v-if="cov === 'haram'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=29.995018005371094%2C31.163856506347656&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 197 Elharram St -
          Eltaawon - Elharram - Cairo
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0235924258</p>
      </div>
      <div class="cairo" v-if="cov === 'faisal'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=29.995174407958984%2C31.150646209716797&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 109 Elmalik Faissal
          St - Giza
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0237714800</p>
      </div>
      <div class="cairo" v-if="cov === 'mohandsen'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.062297821044922%2C31.207759857177734&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 7 Ahmed Orabi St -
          Elagoza - Elmohansen - Giza
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0233043818</p>
      </div>
      <div class="cairo" v-if="cov === 'orabi'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.066179275512695%2C31.200679779052734&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 27 Ahmed Orabi St -
          Elmohansen - Giza
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (9:00 AM) Till (9:00 PM) & Friday (02:00 PM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 033023902</p>
      </div>
      <div class="cairo" v-if="cov === 'badrashin'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=29.84998321533203%2C31.2791805267334&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Elnile Elsaed St In
          front of Eltawhed & Nour - Elbadrashen - Giza
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0238031794</p>
      </div>
      <div class="cairo" v-if="cov === 'october'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=29.9746691%2C30.9428816&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Shop No. (118 A) on
          the ground floor of the commercial and administrative complex called
          (Magda Art Complex), which is located on the plot of land (1-3) in the
          central axis in the Sixth of October City
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0238358160</p>
      </div>
      <div class="cairo" v-if="cov === 'banha'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.466796875%2C31.18027114868164&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 3 Saad Zaghlol -
          Benha - Qalubia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0133251593</p>
      </div>
      <div class="cairo" v-if="cov === 'kafrshokr'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.549270629882812%2C31.26556968688965&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Gamal Abdelnasr St
          with Abdelmonim Ryad St - Kafr Shokr - Qalubia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0132515553</p>
      </div>
      <div class="cairo" v-if="cov === 'shbinq'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.310949325561523%2C31.319400787353516&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : sidi saaed St with
          misr sodan st - Qalubia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (9:00 AM) Till (10:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0132720579</p>
      </div>
      <div class="cairo" v-if="cov === 'smoha'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=31.214370727539062%2C29.944477081298828&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Victor Aman Noel
          Square - Fawzy Moaz Street - Alexandria
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 034283341</p>
      </div>
      <div class="cairo" v-if="cov === 'beshr'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=31.257234573364258%2C29.982641220092773&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 8 Mohamed Nagib St
          - Sidi Bishr - Elmontazah - Alexandria
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 033565684</p>
      </div>
      <div class="cairo" v-if="cov === 'mena'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=31.19633674621582%2C29.885465621948242&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 5 Elnasr St -
          Alexandria
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 034809409</p>
      </div>
      <div class="cairo" v-if="cov === 'montazah'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=31.19633674621582%2C29.885465621948242&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Inside Fathallah
          Mall In front of the montazah gate
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (9:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 035495764</p>
      </div>
      <div class="cairo" v-if="cov === 'gaber'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=31.2191449%2C29.9411974&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 334 El Horreya Road
          Sidi Jaber station
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (9:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 035422649</p>
      </div>
      <div class="cairo" v-if="cov === 'sharm'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=27.865346908569336%2C34.29566955566406&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Old market / Sandy
          Hotel / Sharm El Sheikh
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; All days of the week (10:00
          AM) Till (12:00 AM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0693665157</p>
      </div>
      <div class="cairo" v-if="cov === 'old'">
        <div class="dir">
          <button class="button">
            <a href="https://maps.app.goo.gl/Bt5Q9Zp9JSDp7pWA9">
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Old market / Sharm
          El Sheikh
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; All days of the week (10:00
          AM) Till (12:00 AM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 069/3668349</p>
      </div>
      <div class="cairo" v-if="cov === 'sheraton'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=27.2244407%2C33.8375798&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Sheraton Street -
          Al Mina Area - Plot No. 2381 - Hurghada - Red Sea
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; All days of the week (9:00 AM)
          Till (11:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0653443791</p>
      </div>
      <div class="cairo" v-if="cov === 'safaga'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=26.751983642578125%2C33.94164276123047&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Red Sea 1 nautical
          refrigerator buildings side of the city center east of Safaga highway
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0653256058</p>
      </div>
      <div class="cairo" v-if="cov === 'kawsar'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=27.1959228515625%2C33.82633972167969&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : No. 156 Ahmed Saeed
          Street - Airport Division - First Section Hurghada - Hurghada - Red
          Sea
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; All days of the week (9:00 AM)
          Till (11:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0653406987</p>
      </div>
      <div class="cairo" v-if="cov === 'diarbngm'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.7531051%2C31.4588417&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Al-Nasr Street -
          Ezbet Imran - Derb Negm Center - Al Sharqiya
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0653406987</p>
      </div>
      <div class="cairo" v-if="cov === 'zakazik'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.58409309387207%2C31.49612808227539&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 41 Saad Zaghloul
          Street - Manshiet Abaza - Zagazig - Sharqia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0552344074</p>
      </div>
      <div class="cairo" v-if="cov === 'kafrsakr'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.793052673339844%2C31.628870010375977&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Al-Nasriya street -
          Kafr Saqr - Al-Sharqiya
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0553183412</p>
      </div>
      <div class="cairo" v-if="cov === 'fakos'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.7268674%2C31.7945084&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Saad Zaghloul
          Street - next to Abu Dhabi Islamic Bank - Faqous - Eastern
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0553987578</p>
      </div>
      <div class="cairo" v-if="cov === 'mansora'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=31.04521942138672%2C31.3862361907959&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Sekka Elgdeda
          Street - Safwa Tower - Mansoura - Dakahlia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0502241878</p>
      </div>
      <div class="cairo" v-if="cov === 'elmanzala'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=31.15786361694336%2C31.934120178222656&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Sami Shelbaya
          Street - Al Marwa Building - Ground Floor - Manzala - Dakahlia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0503611167</p>
      </div>
      <div class="cairo" v-if="cov === 'metghamr'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.7202132%2C31.2562413&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Doctors Tower -
          26th of July St - Army Organization - Mit Ghamr - Dakahlia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0504907755</p>
      </div>
      <div class="cairo" v-if="cov === 'shbenelkom'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.55598258972168%2C31.010761260986328&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 2 Abdel Aziz Habib
          Street - Shebin El Kom - Menoufia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0482329750</p>
      </div>
      <div class="cairo" v-if="cov === 'sinbillawain'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.886503219604492%2C31.45836067199707&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : El Senbellaween /
          El Esraa Tower - Sabry Abu Alam Street - in front of Banque Misr
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (10:00 AM) Till (06:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0504670582</p>
      </div>
      <div class="cairo" v-if="cov === 'ashmoun'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.29648%2C30.9795495&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Menoufia
          Governorate, Ashmoun Center, Ashmoun City, next to Banque Misr, Saad
          Street
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (9:00 AM) Till (9:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0483448164</p>
      </div>
      <div class="cairo" v-if="cov === 'mahmoudia'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=31.182525634765625%2C30.52712631225586&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 2 Teraat Al
          Rashidiya Street - Mahmoudiyah - Beheira
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0452500660</p>
      </div>
      <div class="cairo" v-if="cov === 'damanhor'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=31.032615661621094%2C30.45149040222168&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Al-Nahrawi Tower -
          Abdel Salam Al-Shazly Street - with Zuaiter Street - Damanhour City -
          Beheira
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0453147497</p>
      </div>
      <div class="cairo" v-if="cov === 'rashid'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=31.407541275024414%2C30.42401885986328&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : On the Nile
          Corniche Street next to Elshahid Mohamed Arafa Industrial School for
          girls - Rashid
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0452932916</p>
      </div>
      <div class="cairo" v-if="cov === 'baniswaf'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=29.075363159179688%2C31.098243713378906&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Hafez Street with
          Mohamed Hamida Street - Beni Suef
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0822123506</p>
      </div>
      <div class="cairo" v-if="cov === 'kafrelshakh'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps/search/CIB/@31.114532470703125,30.937511444091797,17z?hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address :21 Cooperative
          Society - Al-Sayyad Tower - Salah Salem Street - in front of the
          construction and development crossings
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0473144621</p>
      </div>
      <div class="cairo" v-if="cov === 'desok'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=31.127792358398438%2C30.642366409301758&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Army Street - in
          front of Desouk Sports Club - Desouk - Kafr El-Sheikh
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0472573863</p>
      </div>
      <div class="cairo" v-if="cov === 'tanta'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.7930965423584%2C30.994747161865234&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Al Kawthar Tower -
          No. 84 Al Nahas Street - Tanta - Gharbia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0403353082</p>
      </div>
      <div class="cairo" v-if="cov === 'mahala'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.97117805480957%2C31.167537689208984&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 31 July 23 Street -
          Mahalla - Gharbia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0402214358</p>
      </div>
      <div class="cairo" v-if="cov === 'domiat'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=31.418100357055664%2C31.81785774230957&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 6 El-Galaa Street -
          First Section of Bandar Damietta - Damietta
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0572359201</p>
      </div>
      <div class="cairo" v-if="cov === 'faraskur'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=31.32952880859375%2C31.717863082885742&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Faraskour, Ahmed
          El-Moqaddam St., next to El-Saedy Bakery
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0573443055</p>
      </div>
      <div class="cairo" v-if="cov === 'aswan'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=24.0859387%2C32.8920219&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address :15 Corniche El Nil
          Street / Aswan
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0972450592</p>
      </div>
      <div class="cairo" v-if="cov === 'komombo'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=24.477872848510742%2C32.94780349731445&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address :Port Said Street in
          front of the Security Directorate - Kom Ombo - Aswan
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0973621557</p>
      </div>
      <div class="cairo" v-if="cov === 'aqsor'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=25.70140838623047%2C32.642127990722656&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Karnak Temple
          Street in front of Luxor, back - Luxor Governorate
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (1:00 PM) Till (8:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0952256505</p>
      </div>
      <div class="cairo" v-if="cov === 'qena'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=26.1585887%2C32.7178439&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 4 Obaid Street -
          within the plot D4 - in the area of Bandar Qena - Qena
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (9:00 AM) Till (5:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0963340818</p>
      </div>
      <div class="cairo" v-if="cov === 'qos'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=25.91834259033203%2C32.760162353515625&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Al-Nasr Road in the
          market - in front of Banque Misr - Qus - Qena
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0962214919</p>
      </div>
      <div class="cairo" v-if="cov === 'deshna'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=26.118860244750977%2C32.464759826660156&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Al-Mahatta Street -
          Al-Azayza - Deshna - Qena
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0962750971</p>
      </div>
      <div class="cairo" v-if="cov === 'hamadi'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.7531051%2C31.4588417&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 1 Al-Marwa Street
          from Port Said Street - Nag Hammadi - Qena
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0553767966</p>
      </div>
      <div class="cairo" v-if="cov === 'sohag'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=26.551162719726562%2C31.698392868041992&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Abu Al-Saud Tower -
          Al-Mahatta Street - In front of the railway - Sohag
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0932345245</p>
      </div>
      <div class="cairo" v-if="cov === 'balyana'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps/search/Balyana%20Train%20Station%20(%D9%85%D8%AD%D8%B7%D8%A9%20%D9%82%D8%B7%D8%A7%D8%B1%20%D8%A7%D9%84%D8%A8%D9%84%D9%8A%D8%A7%D9%86%D8%A7)/@26.22674665413219,31.999928838096732,17z?hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : El Gomhoureya St. -
          in front of The station - Hajj Mandour Tower
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0934800037</p>
      </div>
      <div class="cairo" v-if="cov === 'gerga'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=26.33647346496582%2C31.89004898071289&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Al-Madina
          Al-Munawwarah Street - in front of Banque Misr - Gerga
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0934652899</p>
      </div>
      <div class="cairo" v-if="cov === 'monshaa'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=26.4760059%2C31.7997229&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Dr. Hamed Al Sharif
          Street - Sohag
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0932185252</p>
      </div>
      <div class="cairo" v-if="cov === 'tahta'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=26.7689781%2C31.5019077&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 125 Port Said
          Street - Bandar Tahta - Sohag
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0934780101</p>
      </div>
      <div class="cairo" v-if="cov === 'asyut'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=27.18248176574707%2C31.192462921142578&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 10 Al-Sadafi Tower
          - Al-Hilali Street - Bandar Thani Assiut - Assiut
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0882132098</p>
      </div>
      <div class="cairo" v-if="cov === 'qosia'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=27.43979835510254%2C30.825992584228516&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 1 Fatima Al-Zahra
          Tower - Al-Jalaa Street - Al-Qusiya - Assiut
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0882132098</p>
      </div>
      <div class="cairo" v-if="cov === 'banimazar'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=28.49901580810547%2C30.805463790893555&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Taiba Tower -
          Freedom Street - East Mahatta Street - Bani Mazar - Minya
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0863896821</p>
      </div>
      <div class="cairo" v-if="cov === 'mattai'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=28.42081642150879%2C30.789039611816406&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address :Schools Street -
          Mattay Center - Minya
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0863913303</p>
      </div>
      <div class="cairo" v-if="cov === 'menia'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=28.09746551513672%2C30.76091766357422&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Property No. (191
          B, 193 B) on Tenth of Ramadan Street (Corniche)
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0862339339</p>
      </div>
      <div class="cairo" v-if="cov === 'ismailia'">
        <div class="dir">
          <button class="button">
            <a
              href="https://maps.google.com/maps?q=30.5989057%2C32.2755034&z=17&hl=en"
            >
              <div class="icon">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
              <p>Location</p>
            </a>
          </button>
        </div>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 148 Luxor Street
          and Shebeen, before the stadium traffic light
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0643109815</p>
      </div>

      <a @click="allbranches" href="">
        <div class="allbranches">Show All Branches</div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AddressHome",
  data() {
    return {
      cov: "head",
    };
  },
  methods: {
    allbranches() {
      this.redirectTo({ val: "AddressVEN" });
    },
    ...mapActions(["redirectTo"]),
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  direction: ltr;
  font-family: "Ishraq";
  font-size: x-large;
  margin-top: 3%;
}
.title {
  width: 25%;
  text-align: center;
  font-family: "Ishraq";
  font-size: xx-large;
  background-color: #871e35;
  color: white;
  padding: 2%;
  margin-left: 5%;
}
.body {
  width: 65%;
  box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px 15px;
  background-color: white;
  text-align: left;
  padding: 1%;
  margin-left: 20%;
  margin-top: -1%;
}
select {
  background: white;
  color: #871e35;
  border-radius: 0.4em;
  padding: 0.5%;
}

/* From uiverse.io by @fanishah */
.button {
  font-family: inherit;
  background: #871e35;
  color: white;
  padding: 0.35em 0;
  font-size: 17px;
  border: none;
  border-radius: 0.7em;
  letter-spacing: 0.08em;
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  overflow: hidden;
  padding-top: 1.3em;
  padding-left: 2.8em;
  padding-right: 0.9em;
}

.button .icon {
  background: black;
  height: 2em;
  width: 2em;
  border-radius: 2em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0.4em;
  transition: all 0.5s;
}

.button:hover .icon svg {
  transform: rotate(360deg);
}

.button:hover .icon {
  width: calc(100% - 0.85rem);
  border-radius: 0.5em;
}

.dir {
  float: right;
}

a {
  text-decoration: none;
  color: white;
}
a:hover {
  text-decoration: none;
  color: yellow;
}
.allbranches {
  width: 100%;
  text-align: center;
  background-color: #871e35;
}
@media (max-width: 767px) {
  .main {
    width: 100%;
    direction: ltr;
    font-family: "Ishraq";
    font-size: small;
    margin-top: 3%;
    padding: 3%;
  }
  .title {
    width: 50%;
    text-align: center;
    font-family: "Ishraq";
    font-size: large;
    background-color: #871e35;
    color: white;
    padding: 4%;
    margin-left: 5%;
  }
  .body {
    width: 75%;
    box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px 15px;
    background-color: white;
    text-align: left;
    padding: 3%;
    margin-left: 15%;
    margin-top: -1%;
  }
  select {
    background: white;
    color: #871e35;
    border-radius: 0.4em;
    padding: 0.5%;
  }

  /* From uiverse.io by @fanishah */
  .button {
    font-family: inherit;
    background: #871e35;
    color: white;
    padding: 0.35em 0;
    font-size: 17px;
    border: none;
    border-radius: 0.7em;
    letter-spacing: 0.08em;
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    overflow: hidden;
    padding-top: 1.3em;
    padding-left: 2.8em;
    padding-right: 0.9em;
  }

  .button .icon {
    background: black;
    height: 2em;
    width: 2em;
    border-radius: 2em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0.4em;
    transition: all 0.5s;
  }

  .button:hover .icon svg {
    transform: rotate(360deg);
  }

  .button:hover .icon {
    width: calc(100% - 0.85rem);
    border-radius: 0.5em;
  }

  .dir {
    float: right;
  }

  a {
    text-decoration: none;
    color: white;
  }
  a:hover {
    text-decoration: none;
    color: yellow;
  }
  .allbranches {
    width: 100%;
    text-align: center;
    background-color: #871e35;
  }
}
</style>
