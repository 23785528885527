<template>
  <div class="slide">
    <carousel :items-to-show="1">
      <slide v-for="slide in 3" :key="slide">
          <div v-if="slide === 1" class="slide1">
            <div class="eldahbi">Misr Exchange</div>

            <div class="text">Number One in Exchange Services <br/>
                Our clients, individuals or institutions, benefit from the foreign currency exchange service
            </div>
            <br/><br/><br/><br /><br/>
        </div>
        <div v-if = "slide === 2" class = "slide2">
              <h1>Change service for travel abroad </h1>
              <div class="text"><p> Through the nearest branch, you can exchange currency abroad <br/> to find out the nearest branch <a href="#"> Click here </a> </p></div>
          </div>
                  <div v-if = "slide === 3" class = "slide3">
              <h1>Currency rates </h1>
              <p>Prices are announced at the same currency rate as Banque Misr <br /> for exchange rates <a href="#"> click here</a> </p>
          </div>
      </slide>
      <template #addons>
        <navigation />
        <pagination />
      </template>
    </carousel>
  </div>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "SlideShow",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
<style scoped>
.slide {
  width: 90%;
  margin-left: 5%;
}
.eldahbi {
  font-family: "eldahbi";
  font-size: 400%;
}
.text{
  background-color: rgba(255, 255, 255, 0.377);
  color: black;
}
.slide1{
  width: 100%;
  height: 100%;
  background-image: url("../assets/WhatsApp2.jpeg");
  background-size: 100% 100%;
  font-size: xx-large;
  color:  #871e35;
}
.slide2{
  width: 100%;
  height: 100%;
  background-image: url("../assets/WhatsApp4.jpeg");
  background-size: 100% 125%;
  font-size: xx-large;
}
.slide1 h1 {
  margin-left: 30%;
}
.slide1 p {
  margin-left: 30%;
}
.slide3{
  width: 100%;
  height: 100%;
  background-image: url("../assets/WhatsApp1.jpeg");
  background-size: 100% 100%;
  font-size: xx-large;
  color: white;
}
.slide3 a {
  color: white;
}
.slide3 h1 {
  margin-right: 15%;
}
.slide3 p {
  margin-right: 15%;
}
@media screen and (max-width: 650px) {
  .slide {
    width: 90%;
    margin-left: 5%;
  }
  .main {
    width: 100%;

    position: relative;
    direction: rtl;
  }
  .eldahbi {
    font-family: "eldahbi";
    font-size: 300%;
  }
  .text {
    font-size: 100%;
  }
  .one {
    position: absolute;
    width: 60%;
    height: 100%;
    padding-right: 15%;
    direction: rtl;
  }
  .imgslide1 {
    width: 100%;
    height: 100%;
    float: left;
  }
  .slide1{
  width: 100%;
  height: 100%;
  background-image: url("../assets/WhatsApp2.jpeg");
  background-size: 100% 100%;
  font-size: x-large;

}
    .slide2{
  width: 100%;
  height: 100%;
  background-image: url("../assets/WhatsApp4.jpeg");
  background-size: 100% 125%;
  font-size: small;
}
.slide3{
  width: 100%;
  height: 100%;
  background-image: url("../assets/WhatsApp1.jpeg");
  background-size: 100% 125%;
  font-size: small;
  color: white;
}
.text{
  font-size: large;
}
}
</style>
