<template>
<NavBarVueEn />
    <div class="page">
        <h1>Company History</h1>
        <div class="one">
            <img class="one" src="../assets/exchange.jpg" />
        </div>
        <div class="hist">
            <p>
                Misr Exchange Company (an Egyptian joint stock company) was established on 4/5/1991 in accordance with the provisions of the laws in force in the Republic of Egypt
                Arab Egypt and in accordance with the provisions of the Joint Stock Companies Law by Law No. 159 of 1981 and its executive regulations and amendments, and
                Provisions of the Foreign Exchange Law No. 97 of 1976 and its executive regulations in accordance with the provisions of Law No. 38 of 2003 in
                The matter of the Central Bank of Egypt and the banking and monetary system, its amendments and its executive regulations
                <br />The company issued a commercial registry number 16681 on 4/5/1991
                The company was licensed to deal in the purchase and sale of foreign currencies under Ministerial Resolution No. 381 of 1991 dated
                8/8/1991 and published in the Official Gazette No. 183 on 17/8/1991 in accordance with the provisions of Article 3 of the Executive Regulations
                <br />
                The company's activity is governed by Law No. 88 of 2003, its amendments and its executive regulations <br />

            </p>
        </div>
        <hr />
        <h1>Duration of the company </h1>
        <div class="hist">
            <p>
                On 4/26/2016 the Extraordinary General Assembly met and decided to amend Article (5), which states that the specified period
                The company has 25 years, starting from the date of registration of the company in the commercial register, so the specified period for the company becomes 25 years, starting from 3/5/2014
                Until 4/5/2039.

            </p>
        </div>
        <hr />
        <h1>Company Service</h1>
        <div class="hist">
            <p>
                    Buying and selling foreign currencies to clients and to Banque Misr (Inter Bank) through its head office, in addition to 54
                    A branch spread across the governorates of the Arab Republic of Egypt.

            </p>
        </div>
        <hr />
        <h1>Company Charter</h1>
        <div class="hist">
            <h3>Our Vision</h3>
            <ul>
            <p>
                To be the leading company in the exchange market.
            </p></ul>
            <h3>Our message</h3>
            <ul>
            <p>
                Integrity in dealing - gaining customer satisfaction - excellence and quality.
            </p></ul>
            <h3>Our Mission</h3>
            <p>
            <ul>
                <!-- <p>Reaching the branches of the company's to 70 by the end of 2022.</p> -->
                <p>Maximiztion of the profit and equity of the company.</p>
                <p> Maintaining and creating database for the company's clients (internal and external).
                </p>
                <p>Training employees to improve their skills, advantages and teamwork.</p>
            </ul>
            </p>
        </div>
        <!-- <div class="ceo">
            <hr/>
            <h1>Board Members</h1>
            <br/>
            <div class="row">
                <div class="column">
                    <div class="card">
                        
                        <img src="../assets/mradel.png" style="height:300px;" />
                        <div class="container">
                            <br/>
                            <h2>Mr.Adel Fawzy</h2>
                            <p> Chairman </p>
                        </div>
                    </div>
                </div>

                <div class="column">
                    <div class="card">
                        <img src="../assets/mrtarek.png" style="height:300px;" />
                        <div class="container">
                            <h2>Mr.Tarek lotfi</h2>
                            <p>General Manager</p>
                        </div>
                    </div>
                </div>

            </div>
        </div> -->
    </div>
    
</template>
<script>
import NavBarVueEn from '../components/NavBarEn.vue'
export default {
    name: "AboutUs",

    components: {
        NavBarVueEn
    }
}
</script>
<style scoped>
.page {
    direction: ltr;
}

.one {
    width: 80%;
    height: 300px;
    margin-left: 10%;
}


.hist {
    margin-top: 2%;
    text-align: left;
    width: 80%;
    margin-left: 15%;
    font-size: xx-large;
}

h1 {
    color: #880404;
}

h3 {
    color: #880404;
}
hr{
    width: 70%;
    margin-left: 15%;
}
p{
    font-family: "Ishraq";
}
.ceo{
    width: 80%;
    margin-left: 10%;
}
/* Three columns side by side */
.column {
    width: 33.3%;
    padding: 2%
}
.column:hover{
        box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px 5px;
}
/* Display the columns below each other instead of side by side on small screens */
@media screen and (max-width: 650px) {
    .column {
        width: 100%;
        display: block;
    }
    p{
    font-family:sans-serif;
    font-size: small;
}
}

/* Add some shadows to create a card effect */

/* Some left and right padding inside the container */
.container {
    padding: 0 16px;
}

/* Clear floats */
.container::after,
.row::after {
    content: "";
    clear: both;
    display: table;
}

.title {
    color: grey;
}

</style>