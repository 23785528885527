<template>
  <NavBarVueEn />
  <div class="page">
    <CurrenciesPriseEN />
    <h3>Last Update</h3>
    <p>Date : 02-10-2022 &nbsp; 08:14 AM</p>
    <p>Update No : 3035</p>
  </div>
</template>
<script>
import CurrenciesPriseEN from "../components/CurrenciesPriseEN.vue";
import NavBarVueEn from "../components/NavBarEn.vue";
export default {
  name: "PriseV",
  components: {
    CurrenciesPriseEN,
    NavBarVueEn,
  },
};
</script>
<style scoped>
.page {
  direction: rtl;
}
h3 {
  margin-top: 2%;
  font-family: "Ishraq";
  font-size: x-large;
  color: #880404;
}
p {
  font-family: "Ishraq";
}
</style>
