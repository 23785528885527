<template>
  <div class="main">
    <div class="one">
      <div class="card">
        <div class="content">
          <div class="front">
            <h2 class="title">Exchange Currency For Individuals</h2>
            <p class="subtitle">Foreign Currency Can Be Exchanged For Travel</p>
            <p class="subtitle">For More Information</p>
          </div>
          <div class="back">
            <p class="description">
              Our valued customers, you can exchange foreign currency for travel
              abroad from Through the nearest branch of the company to know the
              branches of the company
              <a href="#" @click="branches">Click Here</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="one">
      <div class="card">
        <div class="content2">
          <div class="front">
            <h2 class="title">For Corporate Dealings</h2>
            <p class="subtitle">More Instructions</p>
          </div>
          <div class="back2">
            <p class="description">
              To exchange currency for companies, the company's documents are
              required to communicate With us
              <a href="#" @click="branches">Click Here</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
      <div class="end"></div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "NewsV",
  methods: {
    branches() {
      this.redirectTo({ val: "AddressVEN" });
    },
    ...mapActions(["redirectTo"]),
  },
};
</script>
<style scoped>
.main {
  width: 90%;
  margin: 5%;
  height: 400px;
}
.one {
  width: 23.5%;
  float: left;
  margin: 0.5%;
  height: 100%;
  color: white;
  font-size: large;
  margin-left: 16%;
}
.end {
  clear: both;
}
a {
  color: white;
}
@media screen and (max-width: 650px) {
  .main {
    width: 90%;
    margin: 5%;
    direction: rtl;
    height: 400px;
  }
  .one {
    width: 90%;
    float: right;
    margin-right: 5%;
    height: 100%;
    font-size: large;
  }
}
/* From uiverse.io by @JoseIsra */
.card {
  cursor: pointer;

  height: 100%;
}

.content {
  text-align: center;
  position: relative;
  transition: all 2.25s;
  background-image: url("../assets/5.jpg");
  background-size: 100% 150%;
  height: 100%;
  background-repeat: no-repeat;
  padding: 5em;
  transform-style: preserve-3d;
}
.content2 {
  text-align: center;
  position: relative;
  transition: all 2.25s;
  background-image: url("../assets/7.jpg");
  background-size: 100% 50%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-color: black;
  height: 100%;

  padding: 5em;
  transform-style: preserve-3d;
}

.card:hover .content {
  transform: rotateY(0.5turn);
}

.card:hover .content2 {
  transform: rotateY(0.5turn);
}

.front,
.back {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 2em;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  height: 100%;
}
.front,
.back2 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 2em;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  height: 100%;
}

.title {
  transform: translateZ(5rem);
  font-size: 2rem;
}

.subtitle {
  transform: translateZ(2rem);
}

.back {
  transform: rotateY(0.5turn);
  background-image: url("../assets/5.jpg");
  background-size: 100% 150%;
}
.back2 {
  transform: rotateY(0.5turn);
  background-image: url("../assets/7.jpg");
  background-size: 100% 50%;
  background-position: bottom;
  background-repeat: no-repeat;
}

.description {
  transform: translateZ(3rem);
}
</style>
