<template>
  <div class="page">
    <router-view />
  </div>
  <div class="footer-dark">
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-md-3 item">
            <h3>About</h3>
            <ul>
              <li><a href="#" @click="about">Company</a></li>
              <li><a href="#" @click="branches">branches</a></li>
              <li><a href="#" @click="Careers">Careers</a></li>
            </ul>
          </div>
          <div class="col-md-6 item text">
            <h3>مصر للصرافة</h3>
            <p>إحدى شركات بنك مصر</p>
          </div>
          <div class="col item social">
            <a
              href="https://www.facebook.com/%D9%85%D8%B5%D8%B1-%D9%84%D9%84%D8%B5%D8%B1%D8%A7%D9%81%D8%A9-%D8%A5%D8%AD%D8%AF%D9%89-%D8%B4%D8%B1%D9%83%D8%A7%D8%AA-%D8%A8%D9%86%D9%83-%D9%85%D8%B5%D8%B1-104966715604190"
              ><i class="bi bi-facebook"></i
            ></a>
            &nbsp;&nbsp;
            <a
              href="https://api.whatsapp.com/send?phone=201127533777&app=facebook&entry_point=page_cta"
              ><i class="bi bi-whatsapp"></i
            ></a>
          </div>
        </div>
        <p class="copyright">Misr Exchange © 2022</p>
        <p class="copyright">Designed By IT Team Of Misr Exchange</p>
      </div>
    </footer>
  </div>
  <div class="end"></div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  components: {},
  methods: {
    about() {
      this.redirectTo({ val: "AboutUs" });
    },
    branches() {
      this.redirectTo({ val: "AddressV" });
    },
    Careers() {
      this.redirectTo({ val: "CareerV" });
    },
    ...mapActions(["redirectTo"]),
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Ishraq";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: red;
    }
  }
}

.page {
  min-height: 900px;
  background-color: white;
}
.end {
  clear: both;
}

.footer-dark {
  padding: 1%;
  color: white;
  background-color: black;
  direction: rtl;
  clear: both;
}

.footer-dark h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: xx-large;
  font-family: "eldahbi";
}

.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 14px;
  margin-bottom: 0;
}

.footer-dark ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.6;
}

.footer-dark ul a:hover {
  opacity: 0.8;
}

@media (max-width: 767px) {
  .footer-dark .item:not(.social) {
    text-align: center;
    padding-bottom: 20px;
  }
}

.footer-dark .item.text {
  margin-bottom: 36px;
}

@media (max-width: 767px) {
  .footer-dark .item.text {
    margin-bottom: 0;
  }
}

.footer-dark .item.text p {
  opacity: 0.6;
  margin-bottom: 0;
}

.footer-dark .item.social {
  text-align: center;
}

@media (max-width: 991px) {
  .footer-dark .item.social {
    text-align: center;
    margin-top: 20px;
  }
}

.footer-dark .item.social > a {
  font-size: 30px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);

  color: black;

  background-color: white;
}

.footer-dark .item.social > a:hover {
  opacity: 0.9;
}

.footer-dark .copyright {
  text-align: center;
  padding-top: 24px;
  opacity: 0.3;
  font-size: 13px;
  margin-bottom: 0;
}
</style>
