<template>
  <NavBarVue />
  <div class="page">
    <div class="page1">
      <h1>
        Your satisfaction is our goal.. You can follow up with us through the
        following means
      </h1>
    </div>
    <div class="page2">
      <h3>Telephone the following numbers</h3>
      <ul>
        <li>Main Branch: 01146236280 / 01110001521</li>
        <li>
          To contact the company's branches
          <a href="#" @click="branches">Click here</a>
        </li>
      </ul>
      <h3>Or by e-mail:</h3>
      <p>M.EX@misrexchange.com</p>
      <h3>For complaints and suggestions, please contact via e-mail</h3>
      <p>complaint@misrexchange.com</p>
    </div>
  </div>
</template>
<script>
import NavBarVue from "../components/NavBarEn.vue";
import { mapActions } from "vuex";
export default {
  name: "ContactUsV",
  components: {
    NavBarVue,
  },
  methods: {
    branches() {
      this.redirectTo({ val: "AddressV" });
    },
    ...mapActions(["redirectTo"]),
  },
};
</script>
<style scoped>
.page {
  direction: ltr;
}
h1 {
  margin-top: 20%;
  font-family: "Ishraq";
  font-size: 150%;
}
p {
  font-family: "Ishraq";
}
.page1 {
  color: white;
  width: 100%;
  height: 500px;
  padding: 2%;
  text-align: left;
  font-family: "Ishraq";
  background-image: url("../assets/contact.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80% 100%;
  font-size: x-large;
}
.page1 h1 {
  margin-left: 10%;
}
.page2 {
  width: 90%;
  margin: 5%;
  text-align: left;
  font-family: "Ishraq";
  font-size: xx-large;
}
.page2 h3 {
  font-size: xx-large;
}
@media screen and (max-width: 650px) {
  .page {
    direction: ltr;
  }
  h1 {
    margin-top: 50%;
    font-family: "Ishraq";
    font-size: 100%;
  }
  p {
    font-family: "Ishraq";
  }
  .page1 {
    color: white;
    width: 100%;
    height: 300px;
    padding: 2%;
    text-align: left;
    font-family: "Ishraq";
    background-image: url("../assets/back1.png");
    background-size: 100% 100%;
    font-size: large;
  }
  .page1 h1 {
    margin-right: 10%;
  }
  .page2 {
    width: 90%;
    margin: 5%;
    text-align: left;
    font-family: "Ishraq";
    font-size: x-large;
  }
  .page2 h3 {
    font-size: x-large;
  }
}
</style>
