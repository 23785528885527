<template>
  <NavBarVueEN />
  <div class="page1">
    <h1>Misr Exchange Branches</h1>
    <p>
      The Company Provides Currency Exchange Service Through 70 Branches In
      Egypt
    </p>
    <div class="gov">
      <spam
        >Branches Of Governorate :
        <select v-model="cov">
          <option value="cairo">Cairo</option>
          <option value="giza">Giza</option>
          <option value="qalubia">Qalubia</option>
          <option value="alex">Alexandria</option>
          <option value="sharm">Sharm El Sheikh</option>
          <option value="hurghada">Red Sea</option>
          <option value="sharkia">Sharkia</option>
          <option value="dakhlya">Dakahlia</option>
          <option value="elmonofia">Menoufia</option>
          <option value="elbhira">Beheira</option>
          <option value="baniswaf">Beni Suef</option>
          <option value="kafrelshakh">Kafr El-Sheikh</option>
          <option value="elgharbia">gharbia</option>
          <option value="domiat">Damietta</option>
          <option value="aswan">Aswan</option>
          <option value="aqsor">Luxor</option>
          <option value="qena">Qena</option>
          <option value="sohag">Sohag</option>
          <option value="asyut">Asyut</option>
          <option value="elminya">Minya</option>
          <option value="ismailia">Ismailia</option>
        </select>
      </spam>
    </div>
  </div>
  <br />
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'cairo'">
    <div class="branch">
      <div class="dir1">
        <h3>Head Office</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 6 Ibrahim Nagib -
          Garden City - Cairo (BanqueMisr Garden city Branch)
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Sunday To Thursday (8:30
          AM) Till (3:00 PM)
        </p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.037792205810547%2C31.233171463012695&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Kasr ElNile Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 48 Kasr ElNile -
          Cairo
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 023957467</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.049509048461914%2C31.24477767944336&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Nasr City Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 22 Eltayran St -
          Nasr City - Cairo
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 022623028</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.06378936767578%2C31.325660705566406&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Makram Ebad Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 24 Noureddine
          Bahgat From Makram Ebeid - Nasr City
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 025422649</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.06378936767578%2C31.325660705566406&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>El Maadi Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : No 2/180 St 151/155
          in front of town center maadi - Elmaadi - Cairo
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0227511536</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=29.95889663696289%2C31.252456665039062&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Helmit Elziton Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 13 Ein Shams St -
          Helmit Elziton - Cairo
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0222415072</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps/search/Super%20Infinity%20Gym/@30.11461067199707,31.316980361938477,17z?hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Elmanil Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 9 Elmokhtar St -
          Manil Elrowda - Cairo
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0223659427</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.0122819%2C31.2245559&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Elrehab Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Commercial Shop No
          (189) on the ground floor of the home services group in the Al-Rehab
          project - the commercial market - Al-Rehab
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0226078895</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.0564022064209%2C31.49114418029785&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Masr Elgdeda Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 29 El Hegaz Street
          in front of Maryland Park and next to Farhat El Kababji
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 024527936</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.098590850830078%2C31.323211669921875&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Ain Shams Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Ain Shams Street
          The corner of Ibrahim Abdel Razek Street
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (9:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 024993460</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.12693214416504%2C31.320493698120117&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Hadaya Elqoba Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 6 Wali Elahd st
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (9:00 AM) Till (9:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 026823606</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.12693214416504%2C31.320493698120117&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Opera Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 29 Opera Square -
          Down Town - Cairo
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (9:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0223930571</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.051321029663086%2C31.245182037353516&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!------------------------------------------------------------>
  <div class="cairo" v-if="cov === 'giza'">
    <div class="branch">
      <div class="dir1">
        <h3>Elharram Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 197 Elharram St -
          Eltaawon - Elharram - Cairo
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0235924258</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=29.995018005371094%2C31.163856506347656&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Faissal Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 109 Elmalik Faissal
          St - Giza
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0237714800</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=29.995174407958984%2C31.150646209716797&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Elmohandsen Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 7 Ahmed Orabi St -
          Elagoza - Elmohansen - Giza
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0233043818</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.062297821044922%2C31.207759857177734&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Ahmed Orabi Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 27 Ahmed Orabi St -
          Elmohansen - Giza
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (9:00 AM) Till (9:00 PM) & Friday (02:00 PM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 033023902</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.066179275512695%2C31.200679779052734&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Elbadrshen Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Elnile Elsaed St In
          front of Eltawhed & Nour - Elbadrashen - Giza
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0238031794</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=29.84998321533203%2C31.2791805267334&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>October Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Shop No. (118 A) on
          the ground floor of the commercial and administrative complex called
          (Magda Art Complex), which is located on the plot of land (1-3) in the
          central axis in the Sixth of October City
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0238358160</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=29.9746691%2C30.9428816&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'qalubia'">
    <div class="branch">
      <div class="dir1">
        <h3>Benha Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 3 Saad Zaghlol -
          Benha - Qalubia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0133251593</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.466796875%2C31.18027114868164&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Kafr Shokr Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Gamal Abdelnasr St
          with Abdelmonim Ryad St - Kafr Shokr - Qalubia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0132515553</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.549270629882812%2C31.26556968688965&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Shbin Elqanater Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : sidi saaed St with
          misr sodan st - Qalubia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (9:00 AM) Till (10:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0132720579</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.310949325561523%2C31.319400787353516&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <br />
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'alex'">
    <div class="branch">
      <div class="dir1">
        <h3>Smooha Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Victor Aman Noel
          Square - Fawzy Moaz Street - Alexandria
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 034283341</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.214370727539062%2C29.944477081298828&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Sidi Bishr Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 8 Mohamed Nagib St
          - Sidi Bishr - Elmontazah - Alexandria
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 033565684</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.257234573364258%2C29.982641220092773&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Alexandria port Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 5 Elnasr St -
          Alexandria
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 034809409</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.19633674621582%2C29.885465621948242&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Almontazah Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Inside Fathallah
          Mall In front of the montazah gate
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (9:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 035495764</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.2806649%2C30.0145249&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Sidi Jaber Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 334 El Horreya Road
          Sidi Jaber station
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (9:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 035422649</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.2191449%2C29.9411974&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'sharm'">
    <div class="branch">
      <div class="dir1">
        <h3>Sharm El Sheikh Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Old market / Sandy
          Hotel / Sharm El Sheikh
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; All days of the week (10:00
          AM) Till (12:00 AM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0693665157</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=27.865346908569336%2C34.29566955566406&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Old Market Sharm El Sheikh Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Old market / Sharm
          El Sheikh
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; All days of the week (10:00
          AM) Till (12:00 AM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 069/3668349</p>
      </div>
      <div class="dir">
        <button class="button">
          <a href="https://maps.app.goo.gl/Bt5Q9Zp9JSDp7pWA9">
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Neama Bay Sharm El Sheikh Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Naama Bay - Second
          Walk Next to mcdonalds
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; All days of the week (10:00
          AM) Till (12:00 AM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0693601115</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=27.909927368164062%2C34.325618743896484&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'hurghada'">
    <div class="branch">
      <div class="dir1">
        <h3>Sheraton Hurghada Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Sheraton Street -
          Al Mina Area - Plot No. 2381 - Hurghada - Red Sea
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; All days of the week (9:00 AM)
          Till (11:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0653443791</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=27.2244407%2C33.8375798&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Safaga Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Red Sea 1 nautical
          refrigerator buildings side of the city center east of Safaga highway
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0653256058</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=26.751983642578125%2C33.94164276123047&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Al Kawthar Branch Hurghada</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : No. 156 Ahmed Saeed
          Street - Airport Division - First Section Hurghada - Hurghada - Red
          Sea
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; All days of the week (9:00 AM)
          Till (11:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0653406987</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=27.1959228515625%2C33.82633972167969&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'sharkia'">
    <div class="branch">
      <div class="dir1">
        <h3>Dairb Negm Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Al-Nasr Street -
          Ezbet Imran - Derb Negm Center - Al Sharqiya
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0653406987</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.7531051%2C31.4588417&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>

    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Zagazig branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 41 Saad Zaghloul
          Street - Manshiet Abaza - Zagazig - Sharqia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0552344074</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.58409309387207%2C31.49612808227539&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Kafr Saqr Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Al-Nasriya street -
          Kafr Saqr - Al-Sharqiya
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0553183412</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.793052673339844%2C31.628870010375977&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Faqous Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Saad Zaghloul
          Street - next to Abu Dhabi Islamic Bank - Faqous - Eastern
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0553987578</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.7268674%2C31.7945084&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'dakhlya'">
    <div class="branch">
      <div class="dir1">
        <h3>Mansoura Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Sekka Elgdeda
          Street - Safwa Tower - Mansoura - Dakahlia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0502241878</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.04521942138672%2C31.3862361907959&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Al Manzala Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Sami Shelbaya
          Street - Al Marwa Building - Ground Floor - Manzala - Dakahlia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0503611167</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.15786361694336%2C31.934120178222656&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Mit Ghamr Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Doctors Tower -
          26th of July St - Army Organization - Mit Ghamr - Dakahlia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0504907755</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.7202132%2C31.2562413&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>sinbillawain Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : El Senbellaween /
          El Esraa Tower - Sabry Abu Alam Street - in front of Banque Misr
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (10:00 AM) Till (06:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0504670582</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.886503219604492%2C31.45836067199707&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'elmonofia'">
    <div class="branch">
      <div class="dir1">
        <h3>Shebin El-Koum Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 2 Abdel Aziz Habib
          Street - Shebin El Kom - Menoufia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0482329750</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.55598258972168%2C31.010761260986328&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Ashmoun Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Menoufia
          Governorate, Ashmoun Center, Ashmoun City, next to Banque Misr, Saad
          Street
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (9:00 AM) Till (9:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0483448164</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.29648%2C30.9795495&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'elbhira'">
    <div class="branch">
      <div class="dir1">
        <h3>Mahmoudia Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 2 Teraat Al
          Rashidiya Street - Mahmoudiyah - Beheira
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0452500660</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.182525634765625%2C30.52712631225586&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Damanhour Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Al-Nahrawi Tower -
          Abdel Salam Al-Shazly Street - with Zuaiter Street - Damanhour City -
          Beheira
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0453147497</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.032615661621094%2C30.45149040222168&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Rashid Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : On the Nile
          Corniche Street next to Elshahid Mohamed Arafa Industrial School for
          girls - Rashid
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0452932916</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.407541275024414%2C30.42401885986328&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'baniswaf'">
    <div class="branch">
      <div class="dir1">
        <h3>Beni Suef Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Hafez Street with
          Mohamed Hamida Street - Beni Suef
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0822123506</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=29.075363159179688%2C31.098243713378906&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'kafrelshakh'">
    <div class="branch">
      <div class="dir1">
        <h3>Kafr Elsheikh Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address :21 Cooperative
          Society - Al-Sayyad Tower - Salah Salem Street - in front of the
          construction and development crossings
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0473144621</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps/search/CIB/@31.114532470703125,30.937511444091797,17z?hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Desouq Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Army Street - in
          front of Desouk Sports Club - Desouk - Kafr El-Sheikh
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0472573863</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.127792358398438%2C30.642366409301758&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'elgharbia'">
    <div class="branch">
      <div class="dir1">
        <h3>Tanta Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Al Kawthar Tower -
          No. 84 Al Nahas Street - Tanta - Gharbia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0403353082</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.7930965423584%2C30.994747161865234&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Mahalla Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 31 July 23 Street -
          Mahalla - Gharbia
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0402214358</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.97117805480957%2C31.167537689208984&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'domiat'">
    <div class="branch">
      <div class="dir1">
        <h3>Damietta Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 6 El-Galaa Street -
          First Section of Bandar Damietta - Damietta
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0572359201</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.418100357055664%2C31.81785774230957&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Faraskur Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Faraskour, Ahmed
          El-Moqaddam St., next to El-Saedy Bakery
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0573443055</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.32952880859375%2C31.717863082885742&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'aswan'">
    <div class="branch">
      <div class="dir1">
        <h3>Aswan Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address :15 Corniche El Nil
          Street / Aswan
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0972450592</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=24.0859387%2C32.8920219&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Kom Ombo Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address :Port Said Street in
          front of the Security Directorate - Kom Ombo - Aswan
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0973621557</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=24.477872848510742%2C32.94780349731445&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'aqsor'">
    <div class="branch">
      <div class="dir1">
        <h3>Luxor Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Karnak Temple
          Street in front of Luxor, back - Luxor Governorate
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM) & Friday (1:00 pm) Till (8:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0952256505</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=25.70140838623047%2C32.642127990722656&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'qena'">
    <div class="branch">
      <div class="dir1">
        <h3>Qena Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 4 Obaid Street -
          within the plot D4 - in the area of Bandar Qena - Qena
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (9:00 AM) Till (5:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0963340818</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=26.1585887%2C32.7178439&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Qus Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Al-Nasr Road in the
          market - in front of Banque Misr - Qus - Qena
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0962214919</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=25.91834259033203%2C32.760162353515625&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Deshna Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Al-Mahatta Street -
          Al-Azayza - Deshna - Qena
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0962750971</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=26.118860244750977%2C32.464759826660156&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Nag Hammadi Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 1 Al-Marwa Street
          from Port Said Street - Nag Hammadi - Qena
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0553767966</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.7531051%2C31.4588417&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'sohag'">
    <div class="branch">
      <div class="dir1">
        <h3>Sohag Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Abu Al-Saud Tower -
          Al-Mahatta Street - In front of the railway - Sohag
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0932345245</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=26.551162719726562%2C31.698392868041992&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Jerja Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Al-Madina
          Al-Munawwarah Street - in front of Banque Misr - Gerga
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0934652899</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=26.33647346496582%2C31.89004898071289&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Almonsha Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Dr. Hamed Al Sharif
          Street - Sohag
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0932185252</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=26.4760059%2C31.7997229&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Tahta branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 125 Port Said
          Street - Bandar Tahta - Sohag
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0934780101</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=26.7689781%2C31.5019077&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Baliana branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : El Gomhoureya St. -
          in front of The station - Hajj Mandour Tower
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0934800037</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps/search/Balyana%20Train%20Station%20(%D9%85%D8%AD%D8%B7%D8%A9%20%D9%82%D8%B7%D8%A7%D8%B1%20%D8%A7%D9%84%D8%A8%D9%84%D9%8A%D8%A7%D9%86%D8%A7)/@26.22674665413219,31.999928838096732,17z?hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'asyut'">
    <div class="branch">
      <div class="dir1">
        <h3>Asyut Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 10 Al-Sadafi Tower
          - Al-Hilali Street - Bandar Thani Assiut - Assiut
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0882132098</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=27.18248176574707%2C31.192462921142578&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Qusiyah Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 1 Fatima Al-Zahra
          Tower - Al-Jalaa Street - Al-Qusiya - Assiut
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0882132098</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=27.43979835510254%2C30.825992584228516&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'elminya'">
    <div class="branch">
      <div class="dir1">
        <h3>Bani Mazar Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Taiba Tower -
          Freedom Street - East Mahatta Street - Bani Mazar - Minya
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0863896821</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=28.49901580810547%2C30.805463790893555&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Mattay Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address :Schools Street -
          Mattay Center - Minya
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (4:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0863913303</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=28.42081642150879%2C30.789039611816406&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>Minya Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : Property No. (191
          B, 193 B) on Tenth of Ramadan Street (Corniche)
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (8:30 AM) Till (8:30 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0862339339</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=28.09746551513672%2C30.76091766357422&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <div class="cairo" v-if="cov === 'ismailia'">
    <div class="branch">
      <div class="dir1">
        <h3>Ismailia Branch</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; Address : 148 Luxor Street
          and Shebeen, before the stadium traffic light
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; From Saturday To Thursday
          (10:00 AM) Till (6:00 PM)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0643109815</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.5989057%2C32.2755034&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>Location</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
  </div>

  <br />
</template>
<script>
import NavBarVueEN from "../components/NavBarEn.vue";
export default {
  name: "AddressV",
  components: {
    NavBarVueEN,
  },
  data() {
    return {
      cov: "cairo",
    };
  },
};
</script>
<style scoped>
.page1 {
  direction: ltr;
  height: 600px;
  font-family: "Ishraq";
  background-image: url("../assets/Locat2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  font-size: xx-large;
  color: black;
}

h1 {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  color: #880404;
}

h3 {
  color: #880404;
}

p {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.branch {
  direction: ltr;
  text-align: left;
  width: 70%;
  margin-left: 15%;
  box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px 5px;
  padding: 2%;
  font-size: x-large;
}

@media screen and (max-width: 650px) {
  .branch {
    text-align: left;
    width: 90%;
    margin-right: 5%;
    box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px 5px;
    padding: 5%;
    font-size: small;
    margin-left: 5%;
  }
  .page1 {
    background-size: 100% 100%;
    font-size: large;
    height: 300px;
  }
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: none;
  color: yellow;
}
select {
  background: #880404;
  color: white;
  border-radius: 0.2em;
}
/* From uiverse.io by @fanishah */
.button {
  font-family: inherit;
  background: #880404;
  color: white;
  padding: 0.35em 0;
  font-size: 17px;
  border: none;
  border-radius: 0.7em;
  letter-spacing: 0.08em;
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  overflow: hidden;
  padding-top: 1.3em;
  padding-left: 2.8em;
  padding-right: 0.9em;
}

.button .icon {
  background: black;
  height: 2em;
  width: 2em;
  border-radius: 2em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0.4em;
  transition: all 0.5s;
}

.button:hover .icon svg {
  transform: rotate(360deg);
}

.button:hover .icon {
  width: calc(100% - 0.85rem);
  border-radius: 0.5em;
}

.dir {
  margin-top: 5%;
  float: right;
}

.dir1 {
  width: 75%;
  float: left;
}

.end {
  clear: both;
}
</style>
