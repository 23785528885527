<template>
  <NavBarVue />
  <div class="page1">
    <h1>فروع شركة مصر للصرافة</h1>
    <p>
      تقدم الشركة خدمة استبدال العملات من خلال 70 فرع فى جميع انحاء الجمهورية
    </p>
    <div class="gov">
      <spam
        >فروع محافظة :
        <select v-model="cov">
          <option value="cairo">القاهرة</option>
          <option value="giza">الجيزة</option>
          <option value="qalubia">القليوبية</option>
          <option value="alex">الاسكندرية</option>
          <option value="sharm">شرم الشيخ</option>
          <option value="hurghada">البحر الاحمر</option>
          <option value="sharkia">الشرقية</option>
          <option value="dakhlya">الدقهلية</option>
          <option value="elmonofia">المنوفية</option>
          <option value="elbhira">البحيرة</option>
          <option value="baniswaf">بنى سويف</option>
          <option value="kafrelshakh">كفر الشيخ</option>
          <option value="elgharbia">الغربية</option>
          <option value="domiat">دمياط</option>
          <option value="aswan">اسوان</option>
          <option value="aqsor">الاقصر</option>
          <option value="qena">قنا</option>
          <option value="sohag">سوهاج</option>
          <option value="asyut">اسيوط</option>
          <option value="elminya">المنيا</option>
          <option value="ismailia">الاسماعيلية</option>
        </select>
      </spam>
    </div>
  </div>
  <br />
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'cairo'">
    <div class="branch">
      <div class="dir1">
        <h3>المركز الرئيسي</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : رقم 6 ش ابراهيم
          نجيب / جاردن سيتي أعلي بنك مصر جاردن سيتي
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من الاحد الى الخميس من ( 8:30
          ص ) حتي ( 3:00 م )
        </p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.037792205810547%2C31.233171463012695&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع قصر النيل</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 48 شارع قصر النيل /
          القاهرة
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:00
          ص ) حتي ( 9:00 م ) والجمعة من ( 10:00 ص ) حتي ( 6:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 023957467</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.049509048461914%2C31.24477767944336&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع مدينة نصر</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 22 شارع الطيران /
          مدينة نصر / القاهرة
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:00
          ص ) حتي ( 9:00 م ) والجمعة من ( 10:00 ص ) حتي ( 6:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 022623028</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.06378936767578%2C31.325660705566406&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع مكرم عبيد</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 24 نور الدين بهجت
          من مكرم عبيد - مدينة نصر
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 9:00
          ص ) حتي ( 10:00 م ) و الجمعة من ( 2:00 م ) حتي ( 10:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 025422649</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.052703857421875%2C31.346227645874023&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع المعادي</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : رقم 2/180 شارع
          151/155 امام تاون سنتر المعادي / قسم المعادي / القاهرة
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:00
          ص ) حتي ( 9:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0227511536</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=29.95889663696289%2C31.252456665039062&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع حلمية الزيتون</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 13 شارع عين شمس /
          حلمية الزيتون / القاهرة
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:00
          ص ) حتي ( 9:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0222415072</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps/search/Super%20Infinity%20Gym/@30.11461067199707,31.316980361938477,17z?hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع المنيل</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 9 شارع المختار /
          منيل الروضة / القاهرة
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:00
          ص ) حتي ( 9:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0223659427</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.0122819%2C31.2245559&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع الرحاب</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : المحل التجاري رقم
          (189) بالدور الارضي بمجموعة الخدمات المنزلية بمشروع الرحاب /السوق
          التجاري/الرحاب
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:00
          ص ) حتي ( 9:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0226078895</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.0564022064209%2C31.49114418029785&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع مصر الجديدة</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 29 شارع الحجاز أمام
          حديقة الميريلاند وبجوار فرحات الكبابجي
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 9:00
          ص ) حتي ( 10:00 م ) و الجمعة من ( 2:00 م ) حتي ( 10:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 024527936</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.098590850830078%2C31.323211669921875&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع عين شمس</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 161 شارع عين شمس
          ناصيه شارع ابراهيم عبد الرازق
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 9:00
          ص ) حتي ( 10:00 م ) و الجمعة من ( 2:00 م ) حتي ( 10:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 024993460</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.12693214416504%2C31.320493698120117&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع حدائق القبة</h3>
        <br />
        <p><i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 6ش ولي العهد</p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 9:00
          ص ) حتي ( 10:00 م ) و الجمعة من ( 2:00 م ) حتي ( 10:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 026823606</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.08649444580078%2C31.283159255981445&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع الاوبرا</h3>
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : ميدان الاوبرا- وسط
          البلد - القاهرة
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 9:00
          ص ) حتي ( 10:00 م ) و الجمعة من ( 2:00 م ) حتي ( 10:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0223930571</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.051321029663086%2C31.245182037353516&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
  </div>
  <!------------------------------------------------------------>
  <div class="cairo" v-if="cov === 'giza'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع الهرم</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 197 شارع الهرم /
          التعاون / الهرم / الجيزة
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:00
          ص ) حتي ( 9:00 م ) والجمعة من ( 10:00 ص ) حتي ( 6:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0235924258</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=29.995018005371094%2C31.163856506347656&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع فيصل</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 109 شارع الملك فيصل
          / الجيزة
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:00
          ص ) حتي ( 9:00 م ) والجمعة من ( 10:00 ص ) حتي ( 6:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0237714800</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=29.995174407958984%2C31.150646209716797&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع المهندسين</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 7 شارع أحمد عرابي /
          العجوزة / المهندسين / الجيزة
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:00
          ص ) حتي ( 9:00 م ) والجمعة من ( 10:00 ص ) حتي ( 6:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0233043818</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.062297821044922%2C31.207759857177734&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع احمد عرابى</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : ٢٧ شارع أحمد عرابى
          المهندسين
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 9:00
          ص ) حتي ( 9:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 033023902</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.066179275512695%2C31.200679779052734&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع البدرشين</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع النيل
          السعيدامام التوحيد والنور / البدرشين / الجيزة
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:00
          ص ) حتي ( 9:00 م ) والجمعة من ( 10:00 ص ) حتي ( 6:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0238031794</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=29.84998321533203%2C31.2791805267334&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع اكتوبر</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : ( المحل التجاري رقم
          ( 118 أ ) بالدور الارضي بالمجمع التجاري والاداري والمسمي ( مجمع ماجدة
          للفنون ) والمقام علي قطعة الارض (1-3) بالمحور المركزي بمدينة السادس من
          اكتوبر
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:00
          ص ) حتي ( 9:00 م ) والجمعة من ( 10:00 ص ) حتي ( 6:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0238358160</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=29.9746691%2C30.9428816&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'qalubia'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع بنها</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 3 شارع سعد زغلول /
          بنها / القليوبية
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:00
          ص ) حتي ( 9:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0133251593</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.466796875%2C31.18027114868164&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع كفر شكر</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع جمال عبد
          الناصر مع شارع عبد المنعم رياض / كفر شكر / القليوبية
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:00
          ص ) حتي ( 9:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0132515553</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.549270629882812%2C31.26556968688965&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع شبين القناطر</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع سيدي سعيد
          مقابل شارع مصر والسودان
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 9:00
          ص ) حتي ( 10:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0132720579</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.310949325561523%2C31.319400787353516&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <br />
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'alex'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع سموحة</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : ميدان فيكتور عما
          نويل / شارع فوزي معاذ / الاسكندرية
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 034283341</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.214370727539062%2C29.944477081298828&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع سيدى بشر</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 8 شارع محمد نجيب /
          سيدي بشر / قسم المنتزة / الاسكندرية
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م ) والجمعة من ( 10:00 ص ) حتي ( 6:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 033565684</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.257234573364258%2C29.982641220092773&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع الميناء</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : العقار رقم 5 شارع
          النصر / الاسكندرية
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 034809409</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.19633674621582%2C29.885465621948242&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع المنتزة</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : داخل مول فتح الله
          المنتزه أمام بوابه المنتزه
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 9:30 م ) و الجمعة من ( 3:00 م ) حتي ( 9:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 035495764</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.2806649%2C30.0145249&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع سيدى جابر</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 334 طريق الحريه
          سيدي جابر المحطه
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 9:30 م ) و الجمعة من ( 3:00 م ) حتي ( 9:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 035422649</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.2191449%2C29.9411974&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'sharm'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع شرم الشيخ</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : السوق القديم /فندق
          ساندي / شرم الشيخ
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; طوال ايام الاسبوع ( 10:00 ص )
          حتي ( 12:00 ص )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0693665157</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=27.865346908569336%2C34.29566955566406&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع السوق القديم شرم الشيخ</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : السوق القديم/ شرم
          الشيخ
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; طوال ايام الاسبوع ( 10:00 ص )
          حتي ( 12:00 ص )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 069/3668349</p>
      </div>
      <div class="dir">
        <button class="button">
          <a href="https://maps.app.goo.gl/Bt5Q9Zp9JSDp7pWA9">
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع خليج نعمة شرم الشيخ</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : خليج نعمه-المشاية
          التانيه بجوار macdonlads
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; طوال ايام الاسبوع ( 10:00 ص )
          حتي ( 12:00 ص )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0693601115</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=27.909927368164062%2C34.325618743896484&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'hurghada'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع شيراتون الغردقة</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع الشيراتون /
          منطقة المينا / قطعة رقم 2381 / الغردقة/ البحر الاحمر
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; طوال ايام الاسبوع ( 9:00 ص )
          حتي ( 11:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0653443791</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=27.2244407%2C33.8375798&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع سفاجا</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : / البحر الاحمر 1
          بحري عمارات الثلاجة جهة وسط المدينة شرق طريق سفاجا العام
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0653256058</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=26.751983642578125%2C33.94164276123047&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع الكوثر الغردقة</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : رقم 156 شارع أحمد
          سعيد / تقسيم المطار / قسم أول الغردقة / الغردقة / البحر الاحمر
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; طوال ايام الاسبوع ( 9:00 ص )
          حتي ( 11:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0653406987</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=27.1959228515625%2C33.82633972167969&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>

  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'sharkia'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع ديرب نجم</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع النصر / عزبة
          عمران / مركز ديرب نجم / الشرقية
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; طوال ايام الاسبوع ( 8:30 ص )
          حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0653406987</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.7531051%2C31.4588417&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>

    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع الزقازيق</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 41 شارع سعد زغلول /
          منشية اباظة / الزقازيق / الشرقية
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م ) والجمعة من ( 10:00 ص ) حتي ( 6:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0552344074</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.58409309387207%2C31.49612808227539&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع كفر صقر</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع حي النصرية /
          كفر صقر / الشرقية
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0553183412</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.793052673339844%2C31.628870010375977&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع فاقوس</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع سعد زغلول /
          بجوار مصرف ابو ظبي الاسلامي / فاقوس / الشرقية
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0553987578</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.7268674%2C31.7945084&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'dakhlya'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع المنصورة</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع السكة الجديدة
          / برج الصفوة / المنصورة / الدقهلية
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0502241878</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.04521942138672%2C31.3862361907959&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع المنزلة</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع سامي شلباية
          /عمارة المروة / الدور الارضي / المنزلة / الدقهلية
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0503611167</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.15786361694336%2C31.934120178222656&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع ميت غمر</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : برج الاطباء ش 26
          يوليو/ تنظيم الجيش / ميت غمر / الدقهلية
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0504907755</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.7202132%2C31.2562413&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع السنبلاوين</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : السنبلاوين / برج
          الاسراء - شارع صبرى ابو علم - أمام بنك مصر
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 10:00
          ص ) حتي ( 6:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0504670582</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.886503219604492%2C31.45836067199707&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'elmonofia'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع شبين الكوم</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 2 شارع عبد العزيز
          حبيب / شبين الكوم / المنوفية
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0482329750</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.55598258972168%2C31.010761260986328&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع اشمون</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : محافظه المنوفيه
          مركز اشمون مدينه اشمون بجوار بنك مصر شارع سعد
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 9:00
          ص ) حتي ( 9:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0483448164</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.29648%2C30.9795495&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'elbhira'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع المحمودية</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 2 شارع ترعة
          الرشيدية / المحمودية / البحيرة
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 4:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0452500660</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.182525634765625%2C30.52712631225586&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع دمنهور</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : برج النحراوي شارع
          عبد السلام الشاذلي مع شارع زعيتر مدينة دمنهور / محافظة البحيرة
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م ) والجمعة من ( 10:00 ص ) حتي ( 6:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0453147497</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.032615661621094%2C30.45149040222168&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع رشيد</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : بشارع كورنيش النيل
          بجوار مدرسة الشهيد محمد عرفه الصناعية بنات/مركز رشيد
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0452932916</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.407541275024414%2C30.42401885986328&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'baniswaf'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع بنى سويف</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع حافظ تقاطع
          شارع محمد حميده / بني سويف
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0822123506</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=29.075363159179688%2C31.098243713378906&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'kafrelshakh'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع كفر الشيخ</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : برج الصياد / شارع
          صلاح سالم / امام مزلقان الانشاء والتعمير / قطعة 21 الجمعية التعاونية /
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م ) والجمعة من ( 10:00 ص ) حتي ( 6:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0473144621</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps/search/CIB/@31.114532470703125,30.937511444091797,17z?hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع دسوق</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع الجيش /امام
          نادي دسوق الرياضي / دسوق / كفر الشيخ
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0472573863</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.127792358398438%2C30.642366409301758&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'elgharbia'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع طنطا</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : برج الكوثر / رقم 84
          شارع النحاس / طنطا / الغربية
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0403353082</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.7930965423584%2C30.994747161865234&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع المحلة</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 31 شارع 23يوليو /
          المحلة / الغربية
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0402214358</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.97117805480957%2C31.167537689208984&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'domiat'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع دمياط</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 6 شارع الجلاء / قسم
          اول بندر دمياط / دمياط
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0572359201</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.418100357055664%2C31.81785774230957&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع فارسكور</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : فارسكور ش احمد
          المقدم بجوار مخبوزات الصعيدى
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 10:00
          ص ) حتي ( 6:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0573443055</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=31.32952880859375%2C31.717863082885742&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'aswan'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع اسوان</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 15 شارع كورنيش
          النيل / اسوان
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 4:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0972450592</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=24.0859387%2C32.8920219&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع كوم امبو</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع بورسعيد امام
          مديرية الامن / كوم امبو / اسوان
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 4:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0973621557</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=24.477872848510742%2C32.94780349731445&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'aqsor'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع الاقصر</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع معبد الكرنك
          امام معبد الاقصر البوابة الخلفية – محافظة الاقصر
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م ) والجمعة من ( 1:00 م ) حتي ( 8:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0952256505</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=25.70140838623047%2C32.642127990722656&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'qena'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع قنا</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 4 شارع عبيد / ضمن
          القطعة ض4/ جرد ناحية بندر قنا / قنا
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 9:00
          ص ) حتي ( 5:00 م)
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0963340818</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=26.1585887%2C32.7178439&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع قوص</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : طريق النصر بالسوق /
          امام بنك مصر / قوص / قنا
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0962214919</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=25.91834259033203%2C32.760162353515625&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع دشنا</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع المحطة /
          العزايزة / دشنا / قنا
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 4:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0962750971</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=26.118860244750977%2C32.464759826660156&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع نجع حمادى</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 1شارع المروة مع
          شارع بورسعيد / نجع حمادي / قنا
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0553767966</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.7531051%2C31.4588417&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'sohag'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع سوهاج</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : برج ابو السعود/
          شارع المحطة / امام السكة الحديد / سوهاج
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0932345245</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=26.551162719726562%2C31.698392868041992&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع جرجا</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع المدينة
          المنورة / امام بنك مصر / جرجا
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 4:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0934652899</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=26.33647346496582%2C31.89004898071289&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع المنشأ</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : شارع الدكتور حامد
          الشريف ( شارع المركز القديم سابقا) المنشأة / سوهاج
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 4:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0932185252</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=26.4760059%2C31.7997229&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع طهطا</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 125عوايد شارع
          بورسعيد / بندر طهطا/ سوهاج
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0934780101</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=26.7689781%2C31.5019077&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع البليانا</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : ش الجمهوريه - أمام
          المحطه- برج الحاج مندور
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 10:00
          ص ) حتي ( 6:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0934800037</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps/search/Balyana%20Train%20Station%20(%D9%85%D8%AD%D8%B7%D8%A9%20%D9%82%D8%B7%D8%A7%D8%B1%20%D8%A7%D9%84%D8%A8%D9%84%D9%8A%D8%A7%D9%86%D8%A7)/@26.22674665413219,31.999928838096732,17z?hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'asyut'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع اسيوط</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 10 برج الصدفي /
          شارع الهلالي/ بندر ثان اسيوط / اسيوط
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0882132098</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=27.18248176574707%2C31.192462921142578&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع القوصية</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 1برج فاطمة الزهراء/
          شارع الجلاء / القوصية / اسيوط
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0882132098</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=27.43979835510254%2C30.825992584228516&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>
  <!----------------------------------------------------------------->
  <div class="cairo" v-if="cov === 'elminya'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع بنى مزار</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : برج طيبة / شارع
          الحرية / شرق شارع المحطة / بني مزار / المنيا
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 4:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0863896821</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=28.49901580810547%2C30.805463790893555&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع مطاى</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان :شارع المدارس / مركز
          مطاي / المنيا
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 4:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0863913303</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=28.42081642150879%2C30.789039611816406&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
    <div class="branch">
      <div class="dir1">
        <h3>فرع المنيا</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : العقار رقم ( 191 ب
          ، 193 ب) علي شارعي العاشر من رمضان (الكورنيش)
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 8:30
          ص ) حتي ( 8:30 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0862339339</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=28.09746551513672%2C30.76091766357422&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
  </div>

  <div class="cairo" v-if="cov === 'ismailia'">
    <div class="branch">
      <div class="dir1">
        <h3>فرع الاسماعيلية</h3>
        <br />
        <p>
          <i class="bi bi-geo-alt-fill"></i>&nbsp; العنوان : 148 شارع الاقصر
          وشبين . قبل اشارة مرور الاستاد
        </p>
        <p>
          <i class="bi bi-clock-fill"></i> &nbsp; من السبت الى الخميس من ( 10:00
          ص ) حتي ( 6:00 م )
        </p>
        <p><i class="bi bi-telephone-fill"></i> &nbsp; 0643109815</p>
      </div>
      <div class="dir">
        <button class="button">
          <a
            href="https://maps.google.com/maps?q=30.5989057%2C32.2755034&z=17&hl=en"
          >
            <div class="icon">
              <i class="bi bi-geo-alt-fill"></i>
            </div>
            <p>الاتجاهات</p>
          </a>
        </button>
      </div>
      <div class="end"></div>
    </div>
    <br />
  </div>

  <br />
</template>
<script>
import NavBarVue from "../components/NavBar.vue";
export default {
  name: "AddressV",
  components: {
    NavBarVue,
  },
  data() {
    return {
      cov: "cairo",
    };
  },
};
</script>
<style scoped>
.page1 {
  direction: rtl;
  height: 600px;
  font-family: "Ishraq";
  background-image: url("../assets/Locat2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  font-size: xx-large;
  color: black;
}

h1 {
  font-family: "Ishraq";
  color: #880404;
}

h3 {
  color: #880404;
}

p {
  font-family: "Ishraq";
}

.branch {
  direction: rtl;
  text-align: right;
  width: 70%;
  margin-left: 15%;
  box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px 5px;
  padding: 2%;
  font-size: x-large;
}

@media screen and (max-width: 650px) {
  .branch {
    text-align: right;
    width: 90%;
    margin-right: 5%;
    box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px 5px;
    padding: 5%;
    font-size: small;
    margin-left: 5%;
  }
  .page1 {
    background-size: 100% 100%;
    font-size: large;
    height: 300px;
  }
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: none;
  color: yellow;
}
select {
  background: #880404;
  color: white;
  border-radius: 0.2em;
}
/* From uiverse.io by @fanishah */
.button {
  font-family: inherit;
  background: #880404;
  color: white;
  padding: 0.35em 0;
  font-size: 17px;
  border: none;
  border-radius: 0.7em;
  letter-spacing: 0.08em;
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  overflow: hidden;

  padding-top: 1.3em;
  padding-left: 2.8em;
  padding-right: 0.9em;
}

.button .icon {
  background: black;
  height: 2em;
  width: 2em;
  border-radius: 2em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0.4em;
  transition: all 0.5s;
}

.button:hover .icon svg {
  transform: rotate(360deg);
}

.button:hover .icon {
  width: calc(100% - 0.85rem);
  border-radius: 0.5em;
}

.dir {
  margin-top: 5%;
  float: left;
}

.dir1 {
  width: 75%;
  float: right;
}

.end {
  clear: both;
}
</style>
