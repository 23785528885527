<template>
  <NavBarVue />
  <SlideShow />
  <!-- <SlideShow2 /> -->
  <CompNews />
  <NewsV />
  <CurrenciesPrise />
  <CurranciesCalculator />
  <AddressHome />
  <br />
</template>

<script>
import NavBarVue from "../components/NavBar.vue";
import SlideShow from "../components/SlideShow.vue";
// import SlideShow2 from "../components/SlideShow2.vue";
import CurrenciesPrise from "../components/CurrenciesPrise.vue";
import CurranciesCalculator from "../components/CurranciesCalculator.vue";
import AddressHome from "../components/AddressHome.vue";
import NewsV from "../components/NewsV.vue";
import CompNews from "../components/ComNews.vue";
export default {
  name: "ٍHomePage",

  components: {
    SlideShow,
    CurrenciesPrise,
    CurranciesCalculator,
    AddressHome,
    NewsV,
    NavBarVue,
    CompNews,
  },
};
</script>
<style scoped></style>
