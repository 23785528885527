import router from "@/router/index";
import { createStore } from "vuex";

const state = {
  currancy: [
    {
      usdsell: 30.95,
      usdbuy: 30.85,
      arusd: "دولار امريكى",
      enusd: "USD Dollar",

      eursell: 32.708,
      eurbuy: 32.4789,
      areur: "يورو",
      eneur: "Euro",

      gbpsell: 37.595,
      gbpbuy: 37.3007,
      argbp: "جنية استرلينى",
      engbp: " Sterling Pound ",

      sarsell: 8.252,
      sarbuy: 8.1952,
      arsar: "ريال سعودى",
      ensar: " Saudi Riyal ",

      aedsell: 8.4266,
      aedbuy: 8.3844,
      araed: "درهم اماراتى",
      enaed: " UAE DIRHAM",

      kwdsell: 100.0388,
      kwdbuy: 99.4405,
      arkwd: "دينار كويتى",
      enkwd: " Kuwait Dinar ",

      audsell: 19.6192,
      audbuy: 19.4262,
      araud: "دولار استرالى",
      enaud: "Australian Dollar",

      jpysell: 0.2067,
      jpybuy: 0.2058,
      arjpy: "ين يابانى",
      enjpy: "Japan Yen",

      // seksell:0,
      // sekbuy:0,
      // arsek:"كرونة سويدية",
      // ensek:"SWEDISH KRONA",

      jodsell: 43.6346,
      jodbuy: 42.877,
      arjod: "دينار اردنى ",
      enjod: "Jordanian Dinar",

      chfsell: 34.5386,
      chfbuy: 34.274,
      archf: "فرنك سويسرى",
      enchf: "Swiss Franc",

      bhdsell: 82.0955,
      bhdbuy: 79.7797,
      arbhd: "دينار بحرينى",
      enbhd: "Bahrain Dinar",

      qarsell: 8.5002,
      qarbuy: 8.0203,
      arqar: "ريال قطرى",
      enqar: "QATARI Riyal	",

      omrsell: 80.3959,
      omrbuy: 78.3019,
      aromr: "ريال عمانى",
      enomr: "Oman Riyal	",

      cadsell: 22.5748,
      cadbuy: 22.4527,
      arcad: "دولار كندى",
      encad: "Canadian Dollar",
    },
  ],
};
const getters = {};
const mutations = {
  redirectTo(state, payload) {
    router.push({ name: payload });
  },
};
const actions = {
  redirectTo({ commit }, payload) {
    commit("redirectTo", payload.val);
  },
};
export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {},
});
