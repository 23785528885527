<template>
  <div class="head">
    <div class="english"><a href="#" @click="EN">English</a></div>
    <div class="facebook">
      <a
        href="https://www.facebook.com/%D9%85%D8%B5%D8%B1-%D9%84%D9%84%D8%B5%D8%B1%D8%A7%D9%81%D8%A9-%D8%A5%D8%AD%D8%AF%D9%89-%D8%B4%D8%B1%D9%83%D8%A7%D8%AA-%D8%A8%D9%86%D9%83-%D9%85%D8%B5%D8%B1-104966715604190"
        ><i class="bi bi-facebook"></i> &nbsp;&nbsp; </a
      ><a
        href="https://api.whatsapp.com/send?phone=201127533777&app=facebook&entry_point=page_cta"
        ><i class="bi bi-whatsapp"></i
      ></a>
    </div>
  </div>
  <div class="navbar">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#" @click="home"
          ><img class="logo1" src="../assets/logo10.png" /><img
            class="logo2"
            src="../assets/logo2.jpg"
        /></a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <button @click="aboutus">مصر للصرافة</button>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click="prise">أسعار الصرف</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click="Address">فروعنا</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click="CareerV">الوظائف</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click="ContactUsV">اتصل بنا</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "NavBar",
  methods: {
    aboutus() {
      this.redirectTo({ val: "AboutUs" });
    },
    home() {
      this.redirectTo({ val: "HomePage" });
    },
    prise() {
      this.redirectTo({ val: "PriseV" });
    },
    Address() {
      this.redirectTo({ val: "AddressV" });
    },
    EN() {
      this.redirectTo({ val: "HomePageEN" });
    },
    CareerV() {
      this.redirectTo({ val: "CareerV" });
    },
    ContactUsV() {
      this.redirectTo({ val: "ContactUsV" });
    },
    ...mapActions(["redirectTo"]),
  },
};
</script>
<style scoped>
.navbar {
  width: 100%;
  direction: rtl;
  font-family: "Ishraq";
}

.logo1 {
  width: 80px;
}

.logo2 {
  width: 100px;
}

.navbar-nav {
  margin-right: 10%;
  margin-top: 4%;
  font-size: large;
  width: 80%;
}

.head {
  background-color: #880404;
  height: 1%;
  color: white;
  text-align: left;
  position: relative;
}

.english {
  text-align: center;
  text-decoration: none;
  font-size: large;
  width: 50%;
  color: white;
  float: left;
  background-color: #880404;
}

.facebook {
  width: 50%;
  text-align: center;
  text-decoration: none;
  font-size: large;
  color: white;
  float: right;
  background-color: #880404;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  color: white;

  border-radius: 5px;
}

button {
  padding: 15px 25px;
  border: unset;
  border-radius: 5px;
  color: #880404;
  z-index: 1;
  background: white;
  position: relative;
  font-weight: 1000;
  font-size: 17px;
  transition: all 250ms;
  overflow: hidden;
}

button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 5px;
  background-color: #880404;
  z-index: -1;
  transition: all 200ms;
}

button:hover {
  color: white;
}

button:hover::before {
  width: 100%;
}

ul li {
  margin-right: 5%;
}
</style>
