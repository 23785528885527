<template>
  <NavBarVue />
  <div class="page">
    <CurrenciesPrise />
  </div>
</template>
<script>
import CurrenciesPrise from "../components/CurrenciesPrise.vue";
import NavBarVue from "../components/NavBar.vue";
export default {
  name: "PriseV",
  components: {
    CurrenciesPrise,
    NavBarVue,
  },
};
</script>
<style scoped>
.page {
  direction: rtl;
}
h3 {
  margin-top: 2%;
  font-family: "Ishraq";
  font-size: x-large;
  color: #880404;
}
p {
  font-family: "Ishraq";
}
</style>
