<template>
  <div class="main">
    <div class="title">
      <h1>Foreign Exchange Rates</h1>
    </div>
    <table class="container" v-for="value in currancy" :key="value">
      <thead>
        <tr>
          <td>Currency</td>
          <td>Sell</td>
          <td>Buy</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><img src="../assets/usd.jpg" />US Dollar</td>
          <td>{{ value.usdsell }}</td>
          <td>{{ value.usdbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/eur.jpg" /> EURO</td>
          <td>{{ value.eursell }}</td>
          <td>{{ value.eurbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/gbp.jpg" />Sterling Pound</td>
          <td>{{ value.gbpsell }}</td>
          <td>{{ value.gbpbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/sar.jpg" /> Saudi Riyal</td>
          <td>{{ value.sarsell }}</td>
          <td>{{ value.sarbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/aed.jpg" /> UAE DIRHAM</td>
          <td>{{ value.aedsell }}</td>
          <td>{{ value.aedbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/kwd.jpg" /> Kuwait Dinar</td>
          <td>{{ value.kwdsell }}</td>
          <td>{{ value.kwdbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/aud.jpg" /> Australian Dollar</td>
          <td>{{ value.audsell }}</td>
          <td>{{ value.audbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/jpy.jpg" /> Japan Yen</td>
          <td>{{ value.jpysell }}</td>
          <td>{{ value.jpybuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/sek.jpg" /> SWEDISH KRONA</td>
          <td>{{ value.seksell }}</td>
          <td>{{ value.sekbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/jod.jpg" /> Jordanian Dinar</td>
          <td>{{ value.jodsell }}</td>
          <td>{{ value.jodbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/chf.jpg" /> Swiss Franc</td>
          <td>{{ value.chfsell }}</td>
          <td>{{ value.chfbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/bhd.jpg" /> Bahrain Dinar</td>
          <td>{{ value.bhdsell }}</td>
          <td>{{ value.bhdbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/qar.jpg" /> QATARI Riyal</td>
          <td>{{ value.qarsell }}</td>
          <td>{{ value.qarbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/omr.jpg" />Oman Riyal</td>
          <td>{{ value.omrsell }}</td>
          <td>{{ value.omrbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/cad.jpg" /> Canadian Dollar</td>
          <td>{{ value.cadsell }}</td>
          <td>{{ value.cadbuy }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="end"></div>
</template>
<script>
export default {
  name: "CurrenciesPrise",
  computed: {
    currancy() {
      return this.$store.state.currancy;
    },
  },
};
</script>
<style scoped>
.main {
  width: 95%;
  direction: ltr;
  margin-top: 5%;
}
.title {
  width: 25%;
  background-color: #871e35;
  color: white;
  font-size: large;
  padding: 1%;
  margin-left: 5%;
}
.container {
  text-align: center;
  width: 75%;
  font-family: "Ishraq";
  box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px 15px;
  margin-top: -1%;
  font-size: xx-large;
  margin-left: 20%;
}
thead {
  background-color: #871e35;
  color: white;
  height: 50px;
}
thead tr td {
  box-shadow: rgba(0, 0, 0, 0.35) 3px 2px 5px;
}
tbody td {
  padding: 10px;
}
tbody tr td {
  /* background-color: rgba(253, 206, 206, 0.815); */
  box-shadow: rgba(0, 0, 0, 0.35) 3px 2px 5px;
}
tbody tr td:hover {
  background-color: #871e35;
  color: white;
}
img {
  width: 10%;
  float: left;
  margin-left: 10%;
}
.end {
  clear: both;
}
@media (max-width: 767px) {
  .main {
    width: 100%;
    direction: ltr;
    margin-top: 10%;
  }
  .title {
    width: 50%;
    background-color: #871e35;
    color: white;
    font-size: smaller;
    padding: 1%;
  }
  .container {
    text-align: center;
    width: 75%;
    font-family: "Ishraq";
    box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px 15px;
    margin-top: -1%;
    font-size: small;
  }
  thead {
    background-color: #871e35;
    color: white;
    height: 50px;
  }
  thead tr td {
    box-shadow: rgba(0, 0, 0, 0.35) 3px 2px 5px;
  }
  tbody td {
    padding: 10px;
  }
  tbody tr td {
    /* background-color: rgba(253, 206, 206, 0.815); */
    box-shadow: rgba(0, 0, 0, 0.35) 3px 2px 5px;
  }
  tbody tr td:hover {
    background-color: #871e35;
    color: white;
  }
  img {
    width: 10%;
    float: left;
    margin-left: 10%;
  }
}
</style>
