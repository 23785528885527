<template>
  <div class="main2">
    <div class="title">News</div>
    <!-- <div class="page1">
      <div>
        <img
          src="../assets/pic/alexport.jpeg"
          class="imgone"
          alt="مصر للصرافة"
        />
        <img
          src="../assets/pic/sidibishr.jpeg"
          class="imgone"
          alt="مصر للصرافة"
        />
        <img src="../assets/pic/semoha.jpeg" class="imgone" alt="مصر للصرافة" />
        <div class="textone">
          The company's senior management visit to the Alexandria branches
          (Al-Mina - Smouha - Sidi Bishr - Sidi Gaber) and soon the opening of
          the following company branches (Ahmed Orabi branch) In Giza - Gardens
          of the Dome in Cairo - Montazah in Alexandria - Naama Bay and The old
          market in South Sinai - Ismailia - El Balina in Sohag - Sinbillawain
          in Dakahlia)
        </div>
      </div>
    </div>
    <div class="page1">
      <div>
        <img src="../assets/1.jpeg" class="imgone" alt="مصر للصرافة" />
        <div class="textone">
          Misr Exchange Company announces the opening of new branches in Cairo
          (Ain Shams - Egypt New - Makram Ebeid) to bring the number of the
          company's branches to 57 branches in the governorates of Egypt and
          soon New branches will be opened in other governorates (Shebin
          El-Qanater - Sidi Gaber - Montazah - Sharm El-Sheikh Old Market 2 -
          Sharm El-Sheikh Naama Bay)
        </div>
      </div>
    </div>
    <div class="page1">
      <div>
        <img src="../assets/alaqsor..jpeg" class="imgone" />
        <div class="textone">
          The Luxor branch has been moved at the address: Temple of Karnak
          Street in front of Luxor Temple, back gate - Luxor Governorate. We are
          honored to receive customers on the branch’s appointments from
          Saturday to Thursday from (8:30 AM) til (8:30 PM) and Friday from
          (1:00 PM) until (8:00 PM)
        </div>
      </div>
    </div> -->
    <div class="page1">
      <div>
        <img src="../assets/logo.png" class="imgone" />
        <div class="textone">
          <div>Now In October And Elrehab</div>
          <div>
            The Opera branch were opened, bringing the number of the company’s
            branches to 70, and the company’s branch had to provide a larger
            number of branches in all governorates to know all the company’s
            branches and reach us<a href="#" @click="branch">click here</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="end"></div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "CompNews",
  methods: {
    branch() {
      this.redirectTo({ val: "AddressVEN" });
    },
    ...mapActions(["redirectTo"]),
  },
};
</script>
<style scoped>
.main2 {
  direction: ltr;
  width: 90%;
  margin-left: 5%;
  clear: both;
}
.title {
  width: 25%;
  background-color: #871e35;
  color: white;
  font-size: xx-large;
  padding: 2%;
  margin-top: 5%;
}
.page1 {
  width: 85%;
  background-color: white;
  margin-left: 15%;
  margin-top: -2%;
  text-align: left;
  padding: 1%;
  clear: both;
}
.imgone {
  width: 300px;
  height: 200px;
  float: left;
}
.textone {
  width: 65%;
  height: 100%;
  float: left;
  margin-left: 2%;
  font-size: xx-large;
  margin-top: 3%;
}
.end {
  clear: both;
}
@media screen and (max-width: 650px) {
  .main2 {
    direction: ltr;
    width: 90%;
    margin-left: 5%;
    clear: both;
  }
  .title {
    width: 50%;
    background-color: #871e35;
    color: white;
    font-size: xx-large;
    padding: 2%;
  }
  .page1 {
    width: 85%;
    background-color: white;
    margin-left: 10%;
    margin-top: -2%;
    text-align: left;
    padding: 1%;
    clear: both;
  }
  .imgone {
    width: 100%;
    height: 100%;
    float: left;
  }
  .textone {
    width: 100%;
    height: 100%;
    float: left;
    margin-right: 2%;
    font-size: large;
    margin-top: 5%;
  }
}
</style>
