<template>
  <div class="main">
    <div class="title">
      <h1>أسعار العملات الاجنبية</h1>
    </div>
    <table class="container">
      <thead>
        <tr>
          <td>العمله</td>
          <td>بيع</td>
          <td>شراء</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><img src="../assets/usd.jpg" /> دولار امريكى</td>
          <td>{{ Listofprices.usdsell }}</td>
          <td>{{ Listofprices.usdbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/eur.jpg" /> يورو</td>
          <td>{{ Listofprices.eursell }}</td>
          <td>{{ Listofprices.eurbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/gbp.jpg" /> جنية استرلينى</td>
          <td>{{ Listofprices.gbpsell }}</td>
          <td>{{ Listofprices.gbpbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/sar.jpg" /> ريال سعودى</td>
          <td>{{ Listofprices.sarsell }}</td>
          <td>{{ Listofprices.sarbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/aed.jpg" /> درهم إماراتى</td>
          <td>{{ Listofprices.aedsell }}</td>
          <td>{{ Listofprices.aedbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/kwd.jpg" /> دينار كويتى</td>
          <td>{{ Listofprices.kwdsell }}</td>
          <td>{{ Listofprices.kwdbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/aud.jpg" /> دولار استرالى</td>
          <td>{{ Listofprices.audsell }}</td>
          <td>{{ Listofprices.audbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/jpy.jpg" /> ين يابانى</td>
          <td>{{ Listofprices.jpysell }}</td>
          <td>{{ Listofprices.jpybuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/sek.jpg" /> كرونة سويدية</td>
          <td>{{ Listofprices.seksell }}</td>
          <td>{{ Listofprices.sekbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/jod.jpg" /> دينار أردنى</td>
          <td>{{ Listofprices.jodsell }}</td>
          <td>{{ Listofprices.jodbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/chf.jpg" /> فرنك سويسرى</td>
          <td>{{ Listofprices.chfsell }}</td>
          <td>{{ Listofprices.chfbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/bhd.jpg" /> دينار بحرينى</td>
          <td>{{ Listofprices.bhdsell }}</td>
          <td>{{ Listofprices.bhdbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/qar.jpg" /> ريال قطرى</td>
          <td>{{ Listofprices.qarsell }}</td>
          <td>{{ Listofprices.qarbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/omr.jpg" /> ريال عمانى</td>
          <td>{{ Listofprices.omrsell }}</td>
          <td>{{ Listofprices.omrbuy }}</td>
        </tr>
        <tr>
          <td><img src="../assets/cad.jpg" /> دولار كندى</td>
          <td>{{ Listofprices.cadsell }}</td>
          <td>{{ Listofprices.cadbuy }}</td>
        </tr>
      </tbody>
    </table>
    <h3>اخير تحديث</h3>
    <p>Date : {{ Listofprices.date }} &nbsp; {{ Listofprices.time }}</p>
    <p>رقم النشرة : {{ Listofprices.id }}</p>
  </div>
  <div class="end"></div>
</template>
<script>
import axios from "axios";
export default {
  name: "CurrenciesPrise",
  async mounted() {
    let result = await axios.get("/pricesedite");

    if (result.status === 200) {
      this.Listofprices = result.data.data[0];
    }
  },
  data() {
    return {
      Listofprices: [],
    };
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  direction: rtl;
  margin-top: 5%;
}
.title {
  width: 25%;
  background-color: #871e35;
  color: white;
  font-size: large;
  padding: 1%;
  margin-right: 5%;
}
.container {
  text-align: center;
  width: 100%;
  font-family: "Ishraq";
  box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px 15px;
  margin-top: -1%;
  font-size: xx-large;
}
thead {
  background-color: #871e35;
  color: white;
  height: 50px;
}
thead tr td {
  box-shadow: rgba(0, 0, 0, 0.35) 3px 2px 5px;
}
tbody td {
  padding: 10px;
}
tbody tr td {
  /* background-color: rgba(253, 206, 206, 0.815); */
  box-shadow: rgba(0, 0, 0, 0.35) 3px 2px 5px;
}
tbody tr td:hover {
  background-color: #871e35;
  color: white;
}
img {
  width: 10%;
  float: right;
  margin-right: 10%;
}
@media (max-width: 767px) {
  .main {
    width: 100%;
    direction: rtl;
  }
  .title {
    width: 50%;
    background-color: #871e35;
    color: white;
    font-size: small;
    padding: 1%;
    margin-right: 5%;
  }
  .container {
    text-align: center;
    width: 80%;
    font-family: "Ishraq";
    box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px 15px;
    margin-top: -1%;
    font-size: large;
  }
  thead {
    background-color: #871e35;
    color: white;
    height: 50px;
  }
  thead tr td {
    box-shadow: rgba(0, 0, 0, 0.35) 3px 2px 5px;
  }
  tbody td {
    padding: 10px;
  }
  tbody tr td {
    /* background-color: rgba(253, 206, 206, 0.815); */
    box-shadow: rgba(0, 0, 0, 0.35) 3px 2px 5px;
  }
  tbody tr td:hover {
    background-color: #871e35;
    color: white;
  }
  img {
    width: 10%;
    float: right;
    margin-right: 10%;
  }
}
</style>
