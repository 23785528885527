<template>
  <NavBarEn />
  <SlideShowEN />
  <!-- <SlideShow2EN /> -->
  <CompNewsEN />
  <NewsVEN />
  <CurrenciesPriseEN />
  <CurranciesCalculatorEN />
  <AddressHomeEN />
  <br />
</template>

<script>
import NavBarEn from "../components/NavBarEn.vue";
import SlideShowEN from "../components/SlideShowEN.vue";
import CurrenciesPriseEN from "../components/CurrenciesPriseEN.vue";
import CurranciesCalculatorEN from "../components/CurranciesCalculatorEN.vue";
import AddressHomeEN from "../components/AddressHomeEN.vue";
import NewsVEN from "../components/NewsVEN.vue";
import CompNewsEN from "../components/ComNewsEN.vue";
// import SlideShow2EN from "../components/SlideShow2EN.vue";

export default {
  name: "ٍHomePage",

  components: {
    SlideShowEN,
    CurrenciesPriseEN,
    CurranciesCalculatorEN,
    AddressHomeEN,
    NewsVEN,
    NavBarEn,
    CompNewsEN,
  },
};
</script>
<style scoped></style>
